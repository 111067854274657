<template>
  <b-breadcrumb separator="has-dot-separator" v-if="(path != null)">
    <b-breadcrumb-item tag='router-link' class="has-text-weight-bold" to="/">bazeny-obchod.cz</b-breadcrumb-item>
    <b-breadcrumb-item tag='router-link' :to="'/'+item.url+'?r='+Date.now()" v-for="item in path" :key="item.url" :active="($route.path == item.url)">{{item.nazev}}</b-breadcrumb-item>
    
  </b-breadcrumb>
</template>

<script>
export default {
  name: 'NavigationPath',
  props: {
    type: String,
    theme: String,
    path: [Object, Array]
  },
  
}
</script>

<style scoped>
</style>
