<template>
  <div class="BzOb_FlipCard" :class="{amountget: amount}">
    <div class="BzOb_CartFlyIn" v-if="getting_to_cart" :class="{flyout: got_to_cart}">
      <img :src="'https://www.bazeny-obchod.cz/kramky/zbozi/velke/'+sanitize(product.obrazek)" />
    </div>
    <!--<div class="BzOb_CartFlyInCart" v-if="getting_to_cart_2" :class="{fadeout: got_to_cart_2}">
      <div class="columns is-mobile is-vcentered is-centered is-variable is-3">
        <div class="column is-narrow">
          <b-button type="is-info" class="BzObPd_Minbutton is-invisible">
            <img class="BzObPd_Minicon" src="../../assets/card_heart.svg" />
          </b-button>
        </div>
        <div class="column is-narrow">
          <b-button type="is-success" @click="amount = true" class="BzObPd_Maxbutton">
            <img class="BzObPd_Maxicon" src="../../assets/card_buy_noplus.svg" />
          </b-button>
        </div>
        <div class="column is-narrow">
          <b-button type="is-info" class="BzObPd_Minbutton is-invisible">
            <img class="BzObPd_Minicon" src="../../assets/card_compare.svg" />
          </b-button>
        </div>
      </div>
    </div>-->
    <div class="BzOb_FlipCard_In"
      @click="$ga.event({eventCategory: 'Products',eventAction: 'clickCard',eventLabel: product.nazev})">
      <div class="BzOb_FlipCard_Product">
        <div class="BzOb_ProductCard" :class="{darken_card: darken}">
          <router-link exact tag="div" :to="link_to" class="BzObPd_TopRibbon no-select">
            <div class="BzObPdTr_Pill BzObPdTrPl_Gift no-select" v-if="hasGift">
              <img src="../../assets/gifted.svg" />
            </div>
            <div class="BzObPdTr_Pill BzObPdTrPl_Code no-select is-hidden-tablet-only" v-if="product.rabat">RABAT
              {{product.rabat}}</div>
            <div class="BzObPdTr_Pill BzObPdTrPl_Code no-select is-hidden-tablet-only" v-if="product.sleva">SLEVA
              {{product.sleva}}</div>

            <div class="BzObPdTr_Pill BzObPdTrPl_Jicin no-select" v-if="product.dostupnost < 3">
              <p class="is-hidden-widescreen-only">Na prodejně</p>
              <p class="is-hidden-fullhd is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile">Na prodejně</p>
            </div>
          </router-link>
          <div class="columns is-mobile is-vcentered has-text-left is-variable is-1 is-hidden-tablet">
            <div class="column">
              <router-link exact tag="div" :to="link_to" class="BzObPd_Image">
                <img v-if="item_origin != 'page'"
                  :src="'https://www.bazeny-obchod.cz/kramky/zbozi/velke/'+sanitize(product.obrazek)" />
                <img v-if="item_origin == 'page'" :src="'https://www.bazeny-obchod.cz'+product.obrazek" />
              </router-link>
            </div>
            <div class="column has-text-left ">
              <router-link exact tag="div" :to="link_to" class="BzObPd_Name">
                {{product.nazev}}
              </router-link>
              <router-link exact tag="div" :to="link_to" class="BzObPd_Description">
                <!--<p class="is-hidden">{{ product.kratky_popis.split('.')[0] | stripHTML }}</p>
                <p v-if="product.dostupnost == 1 || product.dostupnost == 1"><b>SKLADEM</b> <small>(náš sklad)</small>
                </p>
                <p v-if="product.dostupnost == 3 || product.dostupnost == 4"><b>SKLADEM</b> <small>(externí
                    sklad)</small></p>
                <p v-if="product.dostupnost == 7 || product.dostupnost == 8"><b>SKLADEM</b> <small>(na
                    objednání)</small></p>-->
                <p v-if="product.dostupnost >= 9"><b>NENÍ SKLADEM</b> </p>
                <p v-else><b>SKLADEM</b> </p>
              </router-link>
            </div>
          </div>
          <router-link exact tag="div" :to="link_to" class="BzObPd_Image is-hidden-mobile">
            <img v-if="item_origin != 'page'"
              :src="'https://www.bazeny-obchod.cz/kramky/zbozi/velke/'+sanitize(product.obrazek)" />
            <img v-if="item_origin == 'page'" :src="'https://www.bazeny-obchod.cz'+product.obrazek" />
          </router-link>
          <div class="BzObPd_Icons is-hidden-mobile" v-if="removable == false">
            <b-button type="is-info" @click="$parent.$parent.add_to_favorites(product, 'card')"
              class="BzObPd_Minbutton MbWhite">
              <img class="BzObPd_Minicon" src="../../assets/card_heart.svg" />
            </b-button>
            <b-button type="is-info" @click="$parent.$parent.add_to_comparison(product, 'card')"
              class="BzObPd_Minbutton MbWhite">
              <img class="BzObPd_Minicon" src="../../assets/card_compare.svg" />
            </b-button>
          </div>
          <router-link exact tag="div" :to="link_to" class="BzObPd_Name is-hidden-mobile">
            {{product.nazev}}
          </router-link>
          <router-link exact tag="div" :to="link_to" class="BzObPd_Description is-hidden-mobile">
            <!--<p class="is-hidden">{{ product.kratky_popis.split('.')[0] | stripHTML }}</p>-->
            <!--<p v-if="product.dostupnost == 1 || product.dostupnost == 1"><b>SKLADEM</b> <small>(náš sklad)</small></p>
            <p v-if="product.dostupnost == 3 || product.dostupnost == 4"><b>SKLADEM</b> <small>(externí sklad)</small>
            </p>
            <p v-if="product.dostupnost == 7 || product.dostupnost == 8"><b>SKLADEM</b> <small>(na objednání)</small>
            </p>-->
            <p v-if="product.dostupnost >= 9"><b>NENÍ SKLADEM</b> </p>
            <p v-else><b>SKLADEM</b> </p>
          </router-link>
          <div class="BzObPd_Separator"></div>
          <div class="BzObPd_Bottom">
            <div class="BzObPd_Buttons">
              <div class="columns is-mobile is-vcentered is-centered is-variable is-3">
             
                <div class="column is-narrow is-hidden-mobile">
                  <div class="BzObPd_Buybutton n">
                    <div class="columns is-mobile is-gapless is-vcentered is-centered">
                      <div class="column is-narrow" v-if="product.typ=='0'">
                        <b-button type="is-success" @click="to_cart_instant" class="BzObPd_Maxbutton">
                          <img class="BzObPd_Maxicon" src="../../assets/card_buy.svg" />
                        </b-button>
                      </div>
                      <div class="column is-narrow" v-if="product.typ=='1'">
                        <b-button type="is-success" :to="link_to" tag="router-link" class="BzObPd_Maxbutton">
                          <img class="BzObPd_Maxicon" src="../../assets/card_buy.svg" />
                        </b-button>
                      </div>
                      <div class="column is-narrow">
                        <div class="BzObPdBy_AmountText" v-if="product.typ=='0'">
                          <span>
                            <b-input v-model="amount_of" class="BzObPdBy_Input"></b-input>
                          </span>
                          <span>ks</span>
                        </div>
                         <div class="BzObPdBy_AmountText" style="padding:10px 15px" v-if="product.typ=='1'">
                          
                          <span>Detail zboží</span>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="column is-narrow is-hidden-tablet" v-if="product.cena != 0">
                  <div class="BzObPd_Buybutton n" @click="amount = true">
                    <div class="columns is-mobile is-gapless is-vcentered is-centered">
                      <div class="column is-narrow">
                        <b-button type="is-success" class="BzObPd_Maxbutton">
                          <img class="BzObPd_Maxicon" src="../../assets/card_buy.svg" />
                        </b-button>
                      </div>
                      <div class="column is-narrow">
                        <p class="BzObPdBy_Priceshow n">{{product.cena.toLocaleString()}} Kč</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="column is-narrow" v-if="removable == true">

                  <b-button type="is-primary" @click="remove_click" class="BzObPd_Maxbutton">
                    <img class="BzObPd_Maxicon" src="../../assets/card_remove.svg" />
                  </b-button>
                </div>
              
              </div>
            </div>
            <div class="BzObPd_Prices">

              <p class="BzObPdPc_PriceNow is-hidden-mobile">
                <span v-if="product.cenapredslevou != 0" class="has-text-link">
                  {{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(product.cenapredslevou)}}
                  > </span>
                {{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(product.cena)}}


              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="BzOb_FlipCard_Amount">
        <div class="BzOb_BackCard">
          <div class="BzObPd_TopRibbon is-reverse no-select">
            <div class="BzObPdTr_Pill BzObPdTrPl_Gift no-select" v-if="hasGift">
              <img src="../../assets/gifted.svg" class="is-invisible" />
            </div>
            <div class="BzObPdTr_Pill is-hidden BzObPdTrPl_Code no-select is-hidden-tablet-only "><span
                class="is-invisible">S200</span></div>

            <div class="BzObPdTr_Pill BzObPdTrPl_Jicin is-reverse no-select" v-if="product.dostupnost < 3">
              <p class="is-hidden-widescreen-only is-invisible"><span class="is-hidden-mobile">Prodejna</span> Jičín</p>
              <p class="is-hidden-fullhd is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile is-invisible">Na
                prodejně</p>
            </div>
          </div>
          <div class="BzObPd_Separator"></div>
          <div class="BzObPd_AmountSelector">
            <router-link exact tag="div" :to="link_to" class="BzObPdAs_Title">{{product.nazev}}</router-link>
            <div class="BzObPdAs_Subtitle">Vyberte množství</div>
            <div class="columns is-mobile is-vcentered is-centered">
              <div class="column is-narrow">
                <b-button type="is-primary is-light" class="BzObPd_Minbutton BzObPd_Amountbutton" @click="amount_rem">-
                </b-button>
              </div>
              <div class="column is-narrow">
                <div class="BzObPdAs_Number" v-if="product.typ=='0'">{{amount_of}} ks</div>
                <div class="BzObPdAs_Number" v-if="product.typ=='1'">{{amount_of}} m</div>
              </div>
              <div class="column is-narrow">
                <b-button type="is-primary is-light" class="BzObPd_Minbutton BzObPd_Amountbutton" @click="amount_add">+
                </b-button>
              </div>
            </div>
          </div>
          <div @click="amount = false" class="BzObPd_Spacer_100"></div>
          <div @click="amount = false" class="BzObPd_Spacer_70"></div>
          <div class="BzObPd_Buybutton" @click="to_cart">
            <div class="columns is-mobile is-gapless is-vcentered is-centered">
              <div class="column is-narrow">
                <b-button type="is-success" class="BzObPd_Maxbutton">
                  <img class="BzObPd_Maxicon" src="../../assets/card_buy.svg" />
                </b-button>
              </div>
              <div class="column is-narrow">
                <p class="BzObPdBy_Priceshow">
                  {{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(product.cena)}}
                </p>
              </div>
            </div>

          </div>
          <b-button type="is-link" class="BzObPd_MobileCloser is-hidden-tablet" @click="amount = false"
            icon-left="close"></b-button>
          <div class="BzObPd_Bottom is-hidden-mobile r" @click="amount = false">
            <div class="BzObPd_Returner">

              <p class="BzObPdPc_ReturnNow">
                <b-button type="is-primary is-darker" @click="amount = false">Obrátit kartu zpět</b-button>
              </p>
            </div>
          </div>
          <div class="BzObPd_Separator"></div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'ProductCard',
    props: {
      product: Object,
      item_origin: String,
      link_to_overwrite: String,
      removable: Boolean
    },
    data() {
      return {
        amount: false,
        amount_of: 1,
        getting_to_cart: false,
        getting_to_cart_2: false,
        darken: false,
        got_to_cart_2: false,
        got_to_cart: false,
      }
    },
    mounted() {

    },
    computed: {
      link_to: function () {
        if (this.link_to_overwrite != null)
          return "/" + this.link_to_overwrite;
        else
          return "/" + this.product.seo_url;
      },
      sale: function () {
        return Math.round(((this.product.cenapredslevou - this.product.cena) / this.product.cenapredslevou) * 100);
      },
      hasGift: function () {
        var id = 0;
        if (typeof (this.product.id_darku) !== "undefined" || this.product.id_darku !== null) {
          id = this.product.id_darku;
        }
        if (id != 0 && id != undefined)
          return true;
        else
          return false;
      },
    },
    methods: {
      sanitize: function (original) {
        if (original != undefined)
          return original.replace("/kramky/zbozi/velke/", "");
        else
          return original;
      },
      amount_add: function () {
        this.amount_of++;
      },
      amount_rem: function () {
        if (this.amount_of > 1) {
          this.amount_of--;
        }
      },
      amount_if: function () {
        if (this.removable == false) {
          this.amount = true;
        }
      },
      remove_click: function () {
        this.$emit('remove_click')
      },
      to_cart: function () {
        this.amount = false;
        setTimeout(() =>
          this.getting_to_cart = true, 500);
        setTimeout(() =>
          this.getting_to_cart_2 = true, 200);
        setTimeout(() =>
          this.got_to_cart = true, 1200);
        setTimeout(() =>
          this.darken = true, 300);
        setTimeout(() =>
          this.darken = false, 1900);
        setTimeout(() =>
          this.getting_to_cart = false, 2300);
        setTimeout(() =>
          this.getting_to_cart_2 = false, 2700);
        setTimeout(() =>
          this.got_to_cart_2 = false, 2900);
       /* this.$ga.event({
          eventCategory: 'Products',
          eventAction: 'addToCartFromCard',
          eventLabel: this.product.nazev
        })*/
        setTimeout(() =>
          this.got_to_cart_2 = true, 2100);
        setTimeout(() => this.$emit('added_to_cart', this.product, this.amount_of), 500);
      },
      to_cart_instant: function () {
        this.$emit('added_to_cart', this.product, this.amount_of);
        this.amount = false;
        setTimeout(() =>
          this.getting_to_cart = true, 500);
        setTimeout(() =>
          this.getting_to_cart_2 = true, 200);
        setTimeout(() =>
          this.got_to_cart = true, 1200);
        setTimeout(() =>
          this.darken = true, 300);
        setTimeout(() =>
          this.darken = false, 1900);
        setTimeout(() =>
          this.getting_to_cart = false, 2300);
        setTimeout(() =>
          this.getting_to_cart_2 = false, 2700);
        setTimeout(() =>
          this.got_to_cart_2 = false, 2900);

        setTimeout(() =>
          this.got_to_cart_2 = true, 2100);
      }
    }
  }
</script>
<style>
  .BzObPdBy_Input,
  .BzObPdBy_Input .input {
    background-color: transparent !important;
    border-color: transparent !important;
    font-weight: bold !important;
    width: 35px !important;
    height: 2.3rem !important;
    text-align: right;
    font-size: 18px !important;
    padding: 2px;
    margin-top: -2px;
  }
</style>
<style scoped>
  .BzObPdBy_AmountText {
    background-color: #EBEBEB;
    display: flex;
    border-top-right-radius: 10px;
    align-items: center;
    padding-right: 20px;
    -webkit-box-shadow: 1px 2px 14px 0px rgba(4, 128, 150, 0.27);
    -moz-box-shadow: 1px 2px 14px 0px rgba(4, 128, 150, 0.27);
    box-shadow: 1px 2px 14px 0px rgba(4, 128, 150, 0.27);
    margin-left: -2px
  }

  .BzObPd_Spacer_100 {
    height: 100px;
  }

  .BzObPd_Spacer_70 {
    height: 38px;
  }

  .BzObPd_Prices,
  .BzObPd_Returner {
    text-align: center;
  }

  .BzObPd_Minbutton.MbWhite {
    background-color: rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 10px 10px white;
    -webkit-box-shadow: 0px 0px 10px 10px white;
    box-shadow: 0px 0px 10px 10px white;
    margin-bottom: 10px;
    margin-right: 6px;
    padding: 2px;
    border-radius: 20px!important;
    opacity: 1;
    transition: all 0.2s ease;
  }

  .BzObPd_Minbutton.MbWhite:hover {
    background-color: lightgray;
    opacity: 1;
  }

  .BzObPd_Icons {
    position: absolute;
    right: 12px;
    top: 25px;
    display: flex;
    flex-direction: column;
  }

  .darken_card {
    filter: brightness(50%);
  }

  .fadeout {
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
  }

  .BzOb_CartFlyIn.flyout {
    -webkit-animation: slide-out-elliptic-bottom-bck 0.7s ease-in both;
    animation: slide-out-elliptic-bottom-bck 0.7s ease-in both;

    z-index: 26001;
  }

  .BzOb_CartFlyIn {
    z-index: 26001;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    overflow: hidden;
  }

  .BzOb_CartFlyInCart {
    margin: auto;
    position: absolute;
    width: 100%;
    bottom: 57.5px;

    z-index: 36002;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  }

  .BzObPd_MobileCloser {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .BzOb_CartFlyIn img {
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }


  .BzObPd_Buybutton:not(.n) {
    -webkit-animation: heartbeat 3.5s ease-in-out infinite both;
    animation: heartbeat 3.5s ease-in-out infinite both;
  }

  .BzObPdBy_Priceshow {
    height: 40px;
    background-color: #4BC6D9;
    font-size: var(--UIMajor);
    font-weight: 900;
    color: rgb(252, 252, 252);
    padding-left: 20px;
    padding-right: 20px;
    line-height: var(--UIMajor);
    flex-direction: column;
    padding-top: 10px;
    border-bottom-right-radius: 12px !important;
    display: flex;
  }

  .BzObPdBy_Priceshow.n {
    height: 40px;
    background-color: #EBEBEB;
    font-size: var(--UIMajor);
    font-weight: bold;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
    line-height: var(--UIMajor);
    flex-direction: column;
    padding-top: 10px;
    border-bottom-right-radius: 12px !important;
    display: flex;
  }

  .BzObPd_AmountSelector {}

  .BzObPd_Minbutton {
    height: 30px;
    width: 30px;
    padding: 0px;
    position: relative;
  }

  .BzObPd_Amountbutton {
    font-size: var(--UIMajor);
    font-weight: 900;
  }

  .BzObPdAs_Number {
    color: white;
    font-size: var(--HeaderMinor);
    font-weight: bold;
  }

  .BzObPdAs_Title {
    color: white;
    font-size: var(--UIMajor);
    font-weight: 900;
  }

  .BzObPdAs_Subtitle {
    color: white;
    font-size: var(--UICompletor);
    margin-bottom: 20px;
  }

  .BzOb_FlipCard {
    width: 100%;
    min-height: 450px;
    perspective: 1000px;
    margin-bottom: 20px;
  }

  .BzOb_FlipCard_In {
    transition: transform 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955);
    transform-style: preserve-3d;
  }

  .BzOb_FlipCard.amountget .BzOb_FlipCard_In {
    transform: rotateY(180deg);
  }

  .BzOb_FlipCard_Product,
  .BzOb_FlipCard_Amount {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }


  .BzOb_FlipCard_Amount {
    transform: rotateY(180deg);
  }


  .BzObPdPc_PriceNow {
    font-size: var(--UIMajor);
    margin-top: 10px;
    font-weight: 900;
    color: black;
  }

  .BzObPdPc_ReturnNow {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .BzObPdPc_PriceThen {
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: var(--UICompletor);
    font-weight: 900;
    color: rgba(0, 0, 0, .45);
    text-decoration: line-through gray solid 2px;
  }

  .BzObPd_Buttons {
    margin-top: -40px;
  }

  .BzObPd_AmountSelector {

    width: 100%;
    text-align: center;
  }

  .BzObPd_Maxbutton {
    height: 55px;
    width: 55px;
    padding: 0px;
    position: relative;
  }



  .BzObPd_Minicon {
    height: 18px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  .BzObPd_Maxicon {
    height: 28px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  .BzObPd_Separator {
    flex: auto;
    flex-grow: 1;
    height: 100%;
  }

  .BzObPd_Bottom {
    max-height: 90px;
    margin-top: 25px;
    padding-bottom: 15px;
    background-color: var(--PooltechnikBlueLight);
    width: 100%;
    border-bottom-right-radius: 15px;
    transition: all 0.5s ease;

    flex: auto;
  }

  .BzObPd_Bottom.r {

    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 15px;
  }

  .BzOb_ProductCard:hover .BzObPd_Bottom {
    background-color: var(--PooltechnikBlue);
    transition: all 0.5s ease;
  }

  .BzOb_ProductCard:hover .BzObPdPc_PriceNow {
    color: white;

  }

  .BzOb_ProductCard:hover .BzObPdPc_PriceThen {
    color: rgba(255, 255, 255, .45);
    text-decoration: line-through white solid 4px;
  }

  .BzOb_ProductCard:hover {
    -webkit-box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.75);

  }

  .BzObPd_Description {
    font-size: var(--UICompletor);
    padding: 20px;
    padding-top: 10px;
    text-align: center;
  }

  .BzObPd_Name {
    font-size: var(--UIMajor);
    font-weight: 900;
    color: var(--PooltechnikBlue);
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .BzObPd_Image {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .BzObPd_Image img {
    position: absolute;
    left: 0;
    height: 160px;
    width: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
  }

  .BzOb_ProductCard {
    width: 100%;
    min-height: 460px;
    padding-top: 20px;
    background-color: white;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.15);
  }

  .BzOb_BackCard {
    width: 100%;
    min-height: 460px;
    padding-top: 20px;
    background-color: var(--PooltechnikBlue);
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    -webkit-box-shadow: inset 0px 0px 25px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 25px -18px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 25px -18px rgba(0, 0, 0, 0.75);

  }

  @media screen and (max-width: 768px) {
    .BzObPd_Prices {
      height: 5px;
    }

    .BzObPd_Description {
      padding: 0px;
      text-align: left;
    }

    .BzOb_FlipCard {
      min-height: 260px;
    }

    .BzObPd_Name {
      text-align: left;
      margin: 0px !important;
      padding-right: 15px;
    }

    .BzObPd_Image {
      height: 160px;
      margin-left: 15px;
      width: calc(100% - 15px);
    }

    .BzObPd_Image img {
      height: 140px;
    }

    .BzOb_ProductCard {
      min-height: 240px;
    }

    .BzObPd_Spacer_100 {
      height: 25px;
    }

    .BzObPd_Spacer_70 {
      height: 0px;
    }

    .BzOb_BackCard {
      min-height: 276px;
    }
  }

  .BzObPdTrPl_Sale {
    background-color: var(--PooltechnikYellow);
  }

  .BzObPdTrPl_Jicin {
    background-color: var(--JicinBlue);
    color: var(--JicinGold);
    padding-left: 20px !important;
    font-weight: bold;
    background-image: url("../../assets/jicin.svg");
    background-repeat: no-repeat;
  }

  .BzObPdTrPl_Jicin.is-reverse {
    background-image: unset;
  }

  .BzObPdTrPl_Gift {
    background-color: var(--PooltechnikOrange);
  }

  .BzObPdTrPl_Gift img {
    height: 14px;
  }

  .BzObPdTrPl_Code {
    background-color: var(--PooltechnikBlue);
    color: white;
    font-weight: bold;
  }

  .BzObPd_TopRibbon {
    position: absolute;
    top: -13px;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    right: 15px;
  }

  .BzObPd_TopRibbon.is-reverse {
    flex-direction: row;
    left: 15px;
  }

  .BzObPdTr_Pill {
    padding: 5px;
    padding-top: 7px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    line-height: 18px;
    border-radius: 50px;
    font-size: 13px;
    display: flex;
    margin-left: 5px;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2022-9-22 11:9:38
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation heartbeat
   * ----------------------------------------
   */
  @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-9-22 11:37:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-bck-center {
    0% {
      -webkit-transform: translateZ(600px);
      transform: translateZ(600px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bck-center {
    0% {
      -webkit-transform: translateZ(600px);
      transform: translateZ(600px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-9-22 11:51:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-elliptic-bottom-bck
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-elliptic-bottom-bck {
    0% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
      transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% -300px;
      transform-origin: 50% -300px;
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
      transform: translateY(600px) rotateX(30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
  }

  @keyframes slide-out-elliptic-bottom-bck {
    0% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
      transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% -300px;
      transform-origin: 50% -300px;
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(10px) rotateX(30deg) scale(0);
      transform: translateY(10px) rotateX(30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
  }

  /* ----------------------------------------------
   * Generated by Animista on 2022-9-22 12:0:18
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation fade-in
   * ----------------------------------------
   */
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* ----------------------------------------------
     * Generated by Animista on 2022-9-22 12:5:34
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info. 
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation fade-out
     * ----------------------------------------
     */
  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
</style>