<template>
  <footer>
    <router-link to="vernostni-program" exact tag="div" class="BzObFt_Banner" v-if="type != 'bannerless'">
      <div class="columns is-variable is-8 is-vcentered fh">
        <div class="column is-narrow">
          <img src="../../assets/sleva.svg" class="BzObFtBn_SaleTag"/>
          <div class="BzObFtBn_SaleTagFiller"></div>
        </div>
        <div class="column">
          <div class="BzObFtBn_Top">Získejte 5% slevu na další nákup!</div>
          <div class="BzObFtBn_Bottom">Staňte se členy našeho věrnostního programu</div>
        </div>
        <div class="column is-narrow">
          <b-button type="is-link is-inverted" size="is-large" class="has-text-weight-bold">...Více informací</b-button>
        </div>
      </div>
    </router-link>
    <div class="BzObFt_Lvl1" :class="type">
      <div class="is-hidden-desktop">
        <div class="columns is-mobile">
          <div class="column is-hidden-tablet"></div>

          <div class="column is-narrow"><img src="../../assets/pooltechnik_stylized.svg" class="BzObFt_Logo"/></div>
          <div class="column"></div>
          <div class="column is-narrow as is-hidden-mobile">
            <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
              <div class="column is-narrow">
                <b-icon icon="email" size="is-small">
                </b-icon>
              </div>
              <div class="column">info@pooltechnik.cz</div>
            </div>
            <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
              <div class="column is-narrow">
                <b-icon icon="phone" size="is-small">
                </b-icon>
              </div>
              <div class="column">+420 491 202 220</div>
            </div>
            <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
              <div class="column is-narrow">
                <b-icon icon="pin" size="is-small">
                </b-icon>
              </div>
              <div class="column">Dělnická 782, 506 01 Jičín</div>
            </div>
          </div>
        </div>
        <div class="is-hidden-mobile">
          <br></div>
      </div>
      <div class="columns is-variable is-8">
        <div class="column is-narrow is-hidden-tablet-only is-hidden-mobile">
          <img src="../../assets/pooltechnik_stylized.svg" class="BzObFt_Logo"/>
          <br>
          <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
            <div class="column is-narrow">
              <b-icon icon="email" size="is-small">
              </b-icon>
            </div>
            <div class="column">info@pooltechnik.cz</div>
          </div>
          <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
            <div class="column is-narrow">
              <b-icon icon="phone" size="is-small">
              </b-icon>
            </div>
            <div class="column">+420 491 202 220</div>
          </div>
          <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
            <div class="column is-narrow">
              <b-icon icon="pin" size="is-small">
              </b-icon>
            </div>
            <div class="column">Dělnická 782, 506 01 Jičín</div>
          </div>
          <br>
        </div>
        <div class="column is-hidden-tablet-only is-hidden-mobile"></div>
        <div class="column is-narrow is-one-quarter-tablet is-narrow-desktop is-hidden-mobile" v-for="link in links"
             :key="link.title">
          <div class="BzObFtLvl_Title" v-html="link.title"></div>
          <ul>
            <li :key="child.title" v-for="child in link.children" class="clickable BzObFtLvl_Clickable">
              <router-link :to="child.url" tag="a" v-if="child.url != null">
                <div v-html="child.title"></div>
              </router-link>
              <a :href="child.outurl" v-html="child.title" target="_blank" v-if="child.outurl != null"></a>
              <p v-if="child.command != null" @click="child.command">{{ child.title }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="is-hidden-tablet bb">
        <b-collapse animation="slidfe" :open="openDropdown == 'top'" @open="openDropdown = 'top'">
          <template #trigger="props">
            <div class="columns is-mobile is-vcentered">
              <div class="column is-narrow BzObMmMnMpDp_Title">Kontakt</div>
              <div class="column"></div>
              <div class="column is-narrow ">
                <b-icon size="is-big" :icon="props.open ? 'chevron-up' : 'chevron-down'">
                </b-icon>
              </div>
            </div>
          </template>

          <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
            <div class="column is-narrow">
              <b-icon icon="email" size="is-small">
              </b-icon>
            </div>
            <div class="column is-narrow">info@pooltechnik.cz</div>
          </div>
          <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
            <div class="column is-narrow">
              <b-icon icon="phone" size="is-small">
              </b-icon>
            </div>
            <div class="column is-narrow">+420 491 202 220</div>
          </div>
          <div class="columns is-variable is-2 is-mobile BzObFt_ContactLine">
            <div class="column is-narrow">
              <b-icon icon="pin" size="is-small">
              </b-icon>
            </div>
            <div class="column is-narrow">Dělnická 782, 506 01 Jičín</div>
          </div>
          <br>
        </b-collapse>
        <div class="BzObMmMnMp_Dropdowns" v-for="link in links" :key="link.title">

          <b-collapse animation="slide" :open="openDropdown == link.title" @open="() => {
            openDropdown = link.title
          }">
            <template #trigger="props">
              <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow BzObMmMnMpDp_Title">{{ link.title }}</div>
                <div class="column"></div>
                <div class="column is-narrow ">
                  <b-icon size="is-big" :icon="props.open ? 'chevron-up' : 'chevron-down'">
                  </b-icon>
                </div>
              </div>
            </template>
            <ul class="BzObMmMnMpDp_Menu">
              <li :key="child.title" v-for="child in link.children">
                <router-link :to="child.url" tag="a" v-if="child.url != null">
                  <div v-html="child.title"></div>
                </router-link>
                <a :href="child.outurl" v-html="child.title" target="_blank" v-if="child.outurl != null"></a>
                <p v-if="child.command !== null">{{ child.title }}</p>
              </li>
            </ul>
          </b-collapse>
        </div>
        <br>
      </div>
    </div>
    <div class="hdiv"></div>


    <div class="BzObFt_Lvl2">
      <p class="BzObFtLvl2_Text">© 2023 POOLTECHNIK s.r.o. Texty na tomto serveru jsou chráněny autorským zákonem.
        Jejich další šíření bez
        souhlasu autora je zakázané.</p>
      <p class="BzObFtLvl2_Text">Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku,
        zároveň je povinen zaevidovat přijatou
        tržbu u správce daně on-line; v případě technického výpadku pak nejpozději do 48 hodin</p>
      <div class="is-hidden-tablet">
        <br>
        <br>
        <br>
      </div>
    </div>
    <br>
  </footer>
</template>

<script>
//<div class="column has-text-grey">
//          <img src="../../assets/pooltechnik.svg" />
//          <address class="address">
//            <p>Dělnická 782</p>
//            <p>506 01 Jičín</p>
//          </address>
//          <div class="Spacer"></div>
//          <b>+420 491 202 220<br>
//            <a class="has-text-grey" href="mailto:info@pooltechnik.cz">info@pooltechnik.cz</a></b>
//
//        </div>
//        <div class="column is-narrow">
//          <div class="vdiv"></div>
//        </div>

export default {
  name: 'NavigationFooter',
  props: {
    type: String
  },
  data() {
    return {
      openDropdown: "",
      links: [{
        title: "O nákupu",
        children: [
          {
            title: "Nastavení cookies",
            command: () => {
              this.$CookieConsent.showPreferences();
            }
          },
          {
            title: "Koronavirus",
            url: "/koronavirus"
          },
          {
            title: "Způsob platby",
            url: "/podminky#platba"
          },
          {
            title: "Dodání",
            url: "/podminky#dodani"
          },
          {
            title: "Obchodní podmínky",
            url: "/podminky"
          },
          {
            title: "Reklamační řád",
            url: "/podminky#reklamace"
          },
        ]
      },
        {
          title: "O nás",
          children: [{
            title: "O POOLTECHNIKu",
            url: "/o-nas"
          },
            {
              title: "Firemní stránky",
              outurl: "https://www.pooltechnik.cz/"
            },
            {
              title: "Kontakty",
              url: "/kontakt"
            },
            {
              title: "Provozní doba",
              url: "/kontakt"
            },
            {
              title: "Spolupracujeme",
              url: "/spolupracujeme"
            },
          ]
        },
        {
          title: "Můj účet",
          children: [{
            title: "Přihlásit se",
            url: "/login"
          },
            {
              title: "Zapomenuté heslo",
              url: "/heslo"
            },
            {
              title: "Registrace partnera",
              url: "/registrace"
            },
          ]
        },
        {
          title: "Poradna",
          children: [{
            title: "Stavba kruhového bazénu",
            outurl: "https://www.bazeny-obchod.cz/bazeny-obchod/soubory/N%C3%A1vody%20baz%C3%A9n/N%C3%A1vod%20na%20stavbu%20baz%C3%A9nu%20-%20kruh.pdf"
          },
            {
              title: "Stavba nadzemního bazénu",
              outurl: "https://www.bazeny-obchod.cz/bazeny-obchod/soubory/Foto%20manual%20pro%20stavbu%20bazenu.pdf"
            },
            {
              title: "Stavba ováleného bazénu",
              outurl: "https://www.bazeny-obchod.cz/bazeny-obchod/soubory/N%C3%A1vody%20baz%C3%A9n/N%C3%A1vod%20na%20stavbu%20baz%C3%A9nu%20-%20ov%C3%A1l.pdf"
            },
            {
              title: "Problémy s bazénovou vodou",
              outurl: "https://www.pooltechnik.cz/nejcastejsi-problemy-s-bazenovou-vodou/"
            },
          ]
        },
      ]
    }
  }
}
</script>
<style scoped>
.as, .bb {
  margin-top: -15px;
}

.BzObMmMnMpDp_Menu {
  margin-top: 20px;
  margin-bottom: 20px;
}

.BzObMmMnMpDp_Title {
  font-size: var(--UIMajor);
  font-weight: 900;
  color: var(--PooltechnikBlue);
  text-transform: uppercase
}

.BzObFt_ContactLine {
  margin: 0;
  margin-bottom: -20px;
}

.BzObFt_Logo {
  width: 300px;
  margin-bottom: 15px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.BzObFtLvl_Clickable a {
  color: rgb(37, 37, 37);
}

.BzObFtLvl_Clickable {
  margin-bottom: 10px;
  color: rgb(39, 39, 39);
  font-size: var(--UICompletor);

}

.BzObFtLvl2_Text {
  font-size: var(--UICompletor);
  color: #999999;
}

.BzObFtLvl2_Foot {
  font-size: var(--UIMinor);
  font-weight: 900;
  margin-top: 5px;
  color: #999999;
}

.vdiv {
  height: calc(100% - 5px);
  width: 1px;
  margin-top: 5px;
  background-color: #d3d3d3;
}

.hdiv {
  width: calc(100% - 5px);
  height: 1px;
  background-color: #d3d3d3;
}

.BzObFtLvl_Title {
  font-size: var(--UICompletor);
  font-weight: bold;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 10px;
}

.BzObFtBn_SaleTagFiller {
  width: 100px;
}

.BzObFt_Lvl2 {
  margin-top: 25px;
  text-align: justify;
  max-width: 1412px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: auto;
}

.BzObFtBn_SaleTag {
  height: 200px;
  position: absolute;
  bottom: 0;
  left: -50px;
}

.Spacer {
  height: 10px;
}

.BzObFt_Lvl1 {
  padding-top: calc(calc(200px / 2) + 20px);
  max-width: 1412px;
  margin: auto;
}

.BzObFt_Lvl1.bannerless {
  padding-top: 50px;

}

.BzObFtBn_Top {
  color: white;
  font-size: var(--HeaderMinor);
  font-weight: bold;
  margin-bottom: -15px;
  text-shadow: 0px 0px 8px rgba(150, 150, 150, 0.77);
}

.BzObFtBn_Bottom {
  color: white;
  font-size: 25px;
  text-shadow: 0px 0px 8px rgba(150, 150, 150, 0.77);
}

.fh {
  height: 100%;
  margin: 0px;
}

footer {
  border-top: 1px solid #e2e2e2;
  background-color: #EBEBEB;
  min-height: 400px;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 1500px) {
  footer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.BzObFt_Banner {
  background-image: url("../../assets/footer.jpg");
  height: 150px;
  margin: auto;
  padding: 10px;
  width: 1412px;
  cursor: pointer;
  top: calc(-150px / 2);
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  left: 0;
  right: 0;
  z-index: 8999;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .BzObFt_Banner {
    display: none;
    padding: 0;
    top: 0;
    height: 0;
  }

  .BzObFt_Lvl1 {
    padding-top: 50px;
  }
}
</style>