<template>
    <div>
    <div  @click="active = true">
      <b-icon icon="share" size="is-large">
      </b-icon></div>

      <b-modal v-model="active">
        <div class="BzOb_Sharing">
          <div class="BzObSh_Image is-hidden-mobile"></div>
          <div class="BzObSh_Info">
            <p class="BzObShIn_TitleRow"><span class="BzObShIn_Title">{{tit}}</span> <span class="BzObShIn_Subtitle">sdílení</span></p>
            <p @click="copyin"><span class="BzObShIn_Url"  ref="mylink"  data-url="url" v-html="url"></span> <b-icon icon="content-copy" type="is-primary" size="is-small">
            </b-icon></p>
            <br>
            <div class="buttons">
              <b-button size="is-large" type="is-light" @click="navigateto('https://www.facebook.com/sharer/sharer.php?u=')" icon-right="facebook"></b-button>
              <b-button size="is-large" type="is-light" @click="navigateto('https://twitter.com/intent/tweet?url=')" icon-right="twitter"></b-button>
              <b-button size="is-large" type="is-light" @click="navigateto('https://api.whatsapp.com/send/?text=')" icon-right="whatsapp"></b-button>
              <b-button size="is-large" type="is-light" class="is-hidden-tablet" @click="navigateto('instagram://sharesheet?text=')" icon-right="instagram"></b-button>
              <b-button size="is-large" type="is-light" @click="navigateto('mailto:?body=')" icon-right="email"></b-button>
            </div>
          </div>
        </div>
       
      </b-modal>
    </div>
   

</template>

<script>
export default {
  name: 'NavigationLogo',
  props: {
    type: String,
    theme: String
  },
  data() {
    return {
      active: false,
      text: "https://www.bazeny-obchod.cz" + this.$route.fullPath
    }
  },
  computed: {
    tit() {
      return document.title;
    },
    url() {
      return "https://www.bazeny-obchod.cz"+this.$route.fullPath;
    }
  },
  methods: {
    navigateto: function(where) {
      window.open(where+this.url, '_blank');

    },
    copyin: function () {
      this.$buefy.snackbar.open({
        message: 'Adresa zkopírována do schránky!',
        position: 'is-top',
      });
      const el = document.createElement('textarea');
      el.value = this.url;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      
    }
  }
}
</script>

<style scoped>
.icon.is-large {
      height: 1rem;
      width: 3rem;
  cursor: pointer;
}
.BzObShIn_TitleRow
{
  margin-bottom: 10px;
}
.BzOb_Sharing
{
  background-color: white;
  
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;

}
.BzObShIn_Url
{
  cursor: pointer;
  color: var(--PooltechnikBlue);
}
.BzObShIn_Title
{
  font-size: var(--UIMajor);
  font-weight: bold;
}
.BzObShIn_Subtitle {
  font-size: var(--UIMajor);
  font-weight: 200;
}
.BzObSh_Info
{
  padding: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
.BzObSh_Image {
  background: var(--PooltechnikBlue);
  background-image: url("../../assets/logo_white.svg");
  background-size: 80%;
  background-position: center;
  background-repeat:no-repeat;
  height: 190px;
    width: 200px;
    border-top-left-radius: 20px;
}
</style>
