<template>
  <div class="BzOb_Account">
    <transition name="zoomr_f" mode="out-in">
      <router-link tag="div" to="/login" v-if="logged == false"><img src="../../assets/account_base.svg" /></router-link>
      <b-dropdown v-model="navigation" position="is-bottom-left" aria-role="menu" v-if="logged == true" >
        <template #trigger>
          <avatar :username="user.informace.jmeno+' '+user.informace.prijmeni" :size="48">
          </avatar>
        </template>
        <b-dropdown-item custom aria-role="menuitem">
          Vítejte <b>{{user.informace.jmeno+' '+user.informace.prijmeni}}</b>
        </b-dropdown-item>
        <hr class="dropdown-divider">

        <b-dropdown-item value="settings" @click="settings">
          <b-icon icon="cog"></b-icon>
          Nastavení
        </b-dropdown-item>
        <b-dropdown-item value="logout" @click="logout" aria-role="menuitem">
          <b-icon icon="logout"></b-icon>
          Odhlášení
        </b-dropdown-item>
      </b-dropdown>

    </transition>
  </div>
</template>

<script>
  import axios from 'axios'
  import Avatar from 'vue-avatar'
  export default {
    name: 'NavigationAccount',
    components: {
      Avatar
    },
    props: {
      logged: Boolean,
      user: [Array, Object]
    },
    data() {
      return {
        navigation: true
      }
    },
    methods: {
      logout: function () {
        var self = this;
        axios.post("https://www.bazeny-obchod.cz/apieshop/prihlaseni/", {
          odhlaseni: "1"
        }).then(function () {
          self.$router.push('/vitejte');
        });
      },
      settings: function () {

        this.$router.push('/uzivatel');
      }
    }
  }
</script>

<style scoped>
  img {
    height: 48px;
    margin-top: 5px;
    pointer-events: none;
  }

  .BzOb_Account {
    cursor: pointer;
  }

  @media screen and (max-width:768px) {
    img {
      height: 36px;
      pointer-events: none;
    }
  }
</style>