<template>
  <div class="MenuAll">

    <div class="pseudobtn lvl has-background-link-light" v-if="current_category" id="allcategories"
      @click="show_all = !show_all">
      <div class="columns is-gapless is-mobile is-vcentered">

        <div class="column has-text-link">
          Všechny kategorie
        </div>
        <div class="column is-narrow">
          <b-icon :icon="show_all ? 'chevron-up' : 'chevron-down'" class=" has-text-link"></b-icon>
        </div>
      </div>
    </div>

    <div class="BzObCtm_Level_1" v-if="!current_category || show_all">
      <div class="BzObCtmLvl1_List" ref="menuwidthel" @mouseenter="in_menu = true" @mouseleave="in_menu = false">
        <div class="pseudobtn_main">
          <div class="pseudobtn lvlN d" v-for="category in categories" :key="category.id"
            :class="{ active: ((hovering_on == category.id && menu_active) || (menu_active && last_hovering == category.id)) }"
            @mouseenter="hovering_on_category(category.id)/*load_subcategories(category.id);*/"
            @mouseleave="stopped_hovering_on_category()"
            @click="clickcat(category.seo_nazev, true, true, category.id, 0)">
            <div class="columns is-gapless is-mobile is-vcentered">
              <div class="column is-narrow menubutton-icon-wrapper pseudobuttonique">
                <img :src="'https://www.bazeny-obchod.cz/'+category.ikonka" v-if="!current_category"
                  class="menubutton-icon" />
                <img :src="'https://www.bazeny-obchod.cz/'+category.ikonka" v-if="current_category"
                  class="menubutton-icon orange" />
                <!--<img :src="require('../../assets/category_icons/' + category.seo_nazev + '.svg')" v-if="!current_category" class="menubutton-icon" />
                <img :src="require('../../assets/category_icons_o/' + category.seo_nazev + '.svg')" v-if="current_category" class="menubutton-icon" />-->
              </div>
              <div class="column">
                {{ category.nazev }}
              </div>

            </div>
          </div>
        </div>
        <router-link to="/nahradni-bazenove-folie" tag="div" v-if="!current_category"
          class="pseudobtn has-background-primary lvl">
          <div class="columns is-gapless is-mobile is-vcentered"
            @mouseenter="in_menu = false;leave_menu();entered_menu = false">
            <div class="column is-narrow menubutton-icon-wrapper pseudobuttonique">
              <img src="../../assets/category_icons/config.svg" class="menubutton-icon" />
            </div>
            <div class="column has-text-white">
              Konfigurátor
            </div>

          </div>
        </router-link>
      </div>
      <div class="BzObCtmLvl1_Menu">
        <div class="BzObCtmLvl1Mn_Body" v-bind:style="styleObj" @mouseenter="entered_menu = true"
          @mouseleave="leave_menu" :class="{active: menu_active, 'top': has_top, margin:current_category}">
          <div class="nothinghereis" v-if="!has_podkategorie"
            style="width: 100%; height:100%; background-size: 50%; background-position:center; background-repeat: no-repeat;"
            :style="{backgroundImage: 'url('+('https://www.bazeny-obchod.cz/carousel/category_icons_shadow_' + ((current_category !=null) ? 'true' : 'false') + '/' + hovering_url + '.svg') +')'}">
            <div
              style="display: flex; justify-content: center; align-items: center; flex-direction: column; height: 100%;">
              <h1 class="title" style="text-align: center;">Zde podkategorie nejsou</h1>

              <router-link class="button is-primary" :to="hovering_url">Zobrazit produkty</router-link>
            </div>
          </div>
          <div class="SlitSplit" v-if="hovering_on != '' && subcategories_menu['c'+hovering_on]"
            @click="leave_menu();done();show_all = false;in_menu = false;menu_active=false;entered_menu=false">
            <div class="SlitBack"
              :style="{ backgroundImage: 'url(https://www.bazeny-obchod.cz/' +/*('https://www.bazeny-obchod.cz/carousel/category_icons_shadow_' + ((current_category !=null) ? 'true' : 'false') + '/' + categories_i[hovering_on].url + '.svg')*/ categories_i[hovering_on].ikonka + ')' }">
            </div>
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <div style="width: 120px"></div>
              </div>
              <div class="column"></div>
              <div class="column is-narrow">
                <p class="subtitle is-5 has-text-weight-bold"
                  :class="{'has-text-link': current_category, 'has-text-primary': !current_category}">
                  {{subcategories_menu['c'+hovering_on].length}} podkategori<span
                    v-if="subcategories_menu['c'+hovering_on].length < 5">e</span><span
                    v-if="subcategories_menu['c'+hovering_on].length > 4">í</span> <span
                    class="has-text-black has-text-weight-normal">v kategorii</span>
                </p>
              </div>
              <div class="column"></div>
              <div class="column is-narrow">
                <b-button size="is-large" icon-left="arrow-right" type="is-light" :to="categories_i[hovering_on].url"
                  tag="router-link">
                </b-button>
              </div>
              <div class="column is-narrow">
                <b-button size="is-large" icon-left="close" type="is-light" id="MenuCloseButton"
                  @click="leave_menu();done();show_all = false;in_menu = false;menu_active=false;entered_menu=false">
                </b-button>
              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-half is-one-third-widescreen is-half-desktop-only is-half-tablet-only"
              v-for="sub in subcategories_menu['c'+hovering_on]" :key="sub.id">
              <div class="columns">
                <div class="column is-narrow">
                  <img :src="'https://bazeny-obchod.cz/' + sub.obr" class="MenuIcon" />
                </div>
                <div class="column">
                  <router-link :to="sub.seo_nazev"
                    :class="{'has-text-link': current_category, 'has-text-primary': !current_category}" tag="a">
                    <b>{{ sub.nazev }}</b></router-link>
                  <ul v-if="sub.podkategorie">

                    <router-link v-for="subbie in sub.podkategorie_data" tag="li" :key="subbie.id"
                      :to="subbie.seo_nazev">
                      {{ subbie.nazev }}
                    </router-link>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="BzObCtm_Level_2" v-if="current_category">

      <div class="BzObCtmLvl2_List">
        <div @click="$router.push(uppercat.url)" class="pseudobtn lvl has-text-white has-background-link "
          :class="{'has-background-link': (level == 2), 'has-background-black': (level > 2) }" v-if="level > 1">
          <div class="columns is-gapless is-mobile is-vcentered">
            <div class="column is-narrow menubutton-icon-wrapper pseudobuttonique">

              <img src="../../assets/category_icons/back.svg" class="menubutton-icon" />
            </div>
            <div class="column">
              Zpět
            </div>

          </div>
        </div>
        <router-link to="/" v-if="level <= 1" tag="div" class="pseudobtn has-background-link lvl">
          <div class="columns is-gapless is-mobile is-vcentered">
            <div class="column is-narrow menubutton-icon-wrapper pseudobuttonique">

              <img src="../../assets/category_icons/back.svg" class="menubutton-icon" />
            </div>
            <div class="column has-text-white">
              Zpět
            </div>

          </div>
        </router-link>
        <div class="pseudobtns" v-for="category in subcategories_menu['c'+current_category]" :key="category.id"
          @click="clickcat(category.seo_nazev, true, true, category.id, 0)">

          <div class="pseudobtn has-backdrop  " :class="'lvl'+level">
            <div class="columns is-gapless is-mobile is-vcentered">

              <div class="column">
                {{ category.nazev }}
              </div>

            </div>
          </div>
          <div class="pseudobtn_backdrop " :class="'lvl'+level"></div>
        </div>


      </div>

    </div>

    <!--<img src="https://www.bazeny-obchod.cz/kramky/sourceimg/banner_vysavace.jpg"/>-->
  </div>
</template>

<script>
  import Axios from "axios";
  import {
    setupCache
  } from 'axios-cache-interceptor';
  const axios = setupCache(Axios);
  export default {
    name: 'CategoryMenu',
    props: {
      wrapper_width: Number,
      current_category: [String, Number],
      level: Number,
      has_top: Boolean,
      navigate: Boolean,
      uppercat: Object,
    },
    mounted() {
      if (this.$refs.menuwidthel !== undefined) {
        this.menuwidth = this.$refs.menuwidthel.clientWidth;
        window.addEventListener('resize', () => {
          this.menuwidth = this.$refs.menuwidthel.clientWidth
        });
      }
    },
    unmounted() {
      window.removeEventListener('resize', () => {
        this.menuwidth = this.$refs.menuwidthel.clientWidth
      });
    },
    methods: {
      leave_menu: function () {
        this.entered_menu = false;
        setTimeout(() => {
          if (this.hovering_on == "") {
            this.menu_active = false;
            this.$emit("menu_kaput");
          }
        }, 600);
      },
      hovering_on_category: function (category) {

        this.load_subcategories(category);
        this.hovering_on = category;
        this.last_hovering = category;
        setTimeout(() => {
          if (this.hovering_on == category) {
            this.$emit("menu_live");
            this.menu_active = true;
          }
        }, 600);

      },
      stopped_hovering_on_category: function () {
        if (!this.in_menu)
          this.hovering_on = "";
        setTimeout(() => {
          if (!this.entered_menu) {
            if (!this.in_menu) {
              this.$emit("menu_kaput");
              this.menu_active = false;
            }
          }
        }, 600);

      },
      clickcat: function (url, condition1, condition2, uid, level) {
        this.show_all = false;
        this.$Progress.start();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        localStorage.setItem("l" + level, condition2);
        localStorage.setItem("d" + level, uid);
        if (condition1 == condition2) {
          if (this.navigate || uid == 1034) {
            this.$router.push({
              path: "/" + url,
              query: {
                _r: Date.now()
              }
            });
            this.$emit("close");
          } else {
            this.load_category_by_uid(uid);
          }
          this.done();
        }
      },
      load_category_by_uid: function (uid) {
        this.$emit("selected", uid);
      },
      done: function () {
        this.$emit("done");
      },
      load_subcategories: function (id) {
        if (id != null) {
          if (!(("c" + id) in this.subcategories_menu)) {
            var self = this;
            var url = "https://www.bazeny-obchod.cz/apieshop/podkategorie/" + id;
            axios.post(url, {
                hloubka: 1
              })
              .then(function (response) {
                self.$set(self.subcategories_menu, "c" + id, response.data.kategorie);

                //load_subsubcategories();
              })
              .catch(function (error) {
                self.$buefy.snackbar.open({
                  duration: 5000,
                  message: "Stala se chyba v načítání podkategorií: " + error,
                  type: 'is-danger',
                  position: 'is-bottom-left',
                  queue: false,

                })
              });
          }
        }


      },
      load_categories: function () {
        var self = this;
        var url = "https://www.bazeny-obchod.cz/apieshop/podkategorie/";
        axios.get(url)
          .then(function (response) {
            self.categories = response.data.kategorie;
            self.categories_i = {};
            self.categories.forEach((category) => {
              self.categories_i[category.id] = category;
              self.categories_i[category.id].url = category.seo_nazev;
            });
            self.load();
          })

      },
      load: function () {
        var self = this;
        if (this.current_category != null) {
          self.load_subcategories(this.current_category)
        }
      }
    },
    created() {
      this.load_categories();

    },

    computed: {
      has_podkategorie() {
        if (this.categories_i[this.hovering_on])
          return JSON.parse(this.categories_i[this.hovering_on].podkategorie);
        else
          return true;
      },
      hovering_url() {
        if (this.categories_i[this.hovering_on])
          return this.categories_i[this.hovering_on].url;
        else
          return 'party-stany';

      }
    },
    watch: {
      wrapper_width: function (now) {
        this.styleObj = {
          "width": 'calc(' + String(now - this.menuwidth) + 'px - 24px)',
          "margin-left": 'calc(' + String(this.menuwidth) + 'px + 24px)'
        }
      },
      menuwidth: function (now) {
        this.styleObj = {
          "width": 'calc(' + String(this.wrapper_width - now) + 'px - 24px)',
          "margin-left": 'calc(' + String(now) + 'px + 24px)'
        }
      },
      current_category: function () {

        this.$Progress.start();
        this.load()
      },
      show_all: function () {
        this.$emit('showing_all', this.show_all);
        if (this.show_all)
          setTimeout(() => this.menuwidth = this.$refs.menuwidthel.clientWidth, 500);

      }
    },
    data() {
      return {
        menu_active: false,
        entered_menu: false,
        menuwidth: 0,
        hovering_on: "",
        show_all: false,
        last_hovering: "",
        in_menu: false,
        styleObj: {
          width: 'calc(' + this.wrapper_width - this.menuwidth + 'px - 24px);'
        },
        subcategories_menu: {},
        current_category2: "",
        categories: [{
          id: "1189",
          nazev: "Bazény, příslušenství, stavba",
          img: "",
          url: "bazeny-prislusenstvi-stavba",
          podkategorie: true
        }, {
          id: "1134",
          nazev: "Mobilní nafukovací vířivky",
          img: "",
          url: "nafukovaci-virivky",
          podkategorie: true
        }, {
          id: "1138",
          nazev: "Infrasauny a doplňky",
          img: "",
          url: "infrasauny-prislusenstvi",
          podkategorie: true
        }, {
          id: "1186",
          nazev: "Finské sauny, doplňky",
          img: "",
          url: "finske-sauny-doplnky",
          podkategorie: true
        }, {
          id: "1281",
          nazev: "Domácí fitness",
          img: "",
          url: "domaci-fitness",
          podkategorie: true
        }, {
          id: "1297",
          nazev: "Koloběžky",
          img: "",
          url: "kolobezky",
          podkategorie: true
        }, {
          id: "1162",
          nazev: "Trampolíny",
          img: "",
          url: "trampoliny",
          podkategorie: true
        }, {
          id: "1163",
          nazev: "Zahradní nábytek",
          img: "",
          url: "zahradni-nabytek",
          podkategorie: true
        }, {
          id: "1315",
          nazev: "Aroma Difuzéry",
          img: "",
          url: "aroma-difuzery",
          podkategorie: false
        }, {
          id: "1305",
          nazev: "Dezinfekce prostor",
          img: "",
          url: "prostorova-dezinfekce",
          podkategorie: true
        }, {
          id: "1351",
          nazev: "Fontány",
          img: "",
          url: "fontany",
          podkategorie: false
        }, {
          id: "1356",
          nazev: "Skleníky",
          img: "",
          url: "skleniky",
          podkategorie: false
        }, {
          id: "1183",
          nazev: "Zahradní domky",
          img: "",
          url: "zahradni-domky",
          podkategorie: true
        }],
        categories_i: {
          "1189": {
            id: "1189",
            nazev: "Bazény, příslušenství, stavba",
            img: "",
            url: "bazeny-prislusenstvi-stavba",
            podkategorie: true
          },
          "1134": {
            id: "1134",
            nazev: "Mobilní nafukovací vířivky",
            img: "",
            url: "nafukovaci-virivky",
            podkategorie: true
          },
          "1138": {
            id: "1138",
            nazev: "Infrasauny a doplňky",
            img: "",
            url: "infrasauny-prislusenstvi",
            podkategorie: true
          },
          "1186": {
            id: "1186",
            nazev: "Finské sauny, doplňky",
            img: "",
            url: "finske-sauny-doplnky",
            podkategorie: true
          },
          "1281": {
            id: "1281",
            nazev: "Domácí fitness",
            img: "",
            url: "domaci-fitness",
            podkategorie: true
          },
          "1297": {
            id: "1297",
            nazev: "Koloběžky",
            img: "",
            url: "kolobezky",
            podkategorie: true
          },
          "1162": {
            id: "1162",
            nazev: "Trampolíny",
            img: "",
            url: "trampoliny",
            podkategorie: true
          },
          "1163": {
            id: "1163",
            nazev: "Zahradní nábytek",
            img: "",
            url: "zahradni-nabytek",
            podkategorie: true
          },
          "1315": {
            id: "1315",
            nazev: "Aroma Difuzéry",
            img: "",
            url: "aroma-difuzery",
            podkategorie: false
          },
          "1305": {
            id: "1305",
            nazev: "Dezinfekce prostor",
            img: "",
            url: "prostorova-dezinfekce",
            podkategorie: true
          },
          "1351": {
            id: "1351",
            nazev: "Fontány",
            img: "",
            url: "fontany",
            podkategorie: false
          },
          "1356": {
            id: "1356",
            nazev: "Skleníky",
            img: "",
            url: "skleniky",
            podkategorie: false
          },
          "1183": {
            id: "1183",
            nazev: "Zahradní domky",
            img: "",
            url: "zahradni-domky",
            podkategorie: true
          }
        },
      }
    }
  }
</script>

<style scoped>
  .MenuIcon {
    width: 60px;
    height: auto;
  }

  .BzObCtm_Level_2 {
    min-width: 250px
  }

  .BzObCtmLvl1Mn_Body {
    height: 100vh;
    opacity: 0;
    min-height: 600px;
    pointer-events: none;

    position: fixed;
    top: 0px;
    z-index: 300;
    overflow: scroll;
    padding: 20px 25px;
  }



  .BzObCtmLvl1Mn_Body.active {
    opacity: 100%;
    pointer-events: all;
    background-color: white;
  }

  .BzObCtm_Level_1 {
    position: relative;
  }

  li {
    cursor: pointer
  }

  li:hover {
    font-weight: bold
  }

  .pseudobtn.lvl0 {
    margin-bottom: 15px !important;
  }

  .pseudobtn.lvl4 {
    margin-bottom: 15px !important;
    margin-top: 10px;
    margin-left: 15px;
  }

  .pseudobtns {
    margin-bottom: 10px !important;


    width: calc(100%) !important;
  }

  .pseudobtns.lvl1 {
    padding-left: 15px;
    margin-bottom: 15px !important;
    width: calc(100% - 15px);
  }

  .pseudobtns.lvl2,
  .pseudobtns.lvl3 {
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 15px !important;
    width: calc(100% - 15px);
  }

  .BzObPcCd_Filter.lvl1 {
    padding-left: 15px;
  }

  .BzObPcCd_Filter.lvl2 {
    margin-top: 10px;
    padding-left: 15px;
  }

  .BzObPcCd_Filter.lvl3 {
    margin-top: 10px;
    padding-left: 15px;
  }

  .BzObPcCd_Filter {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .BzObPcCd_FilterBorder {
    height: 10px;
    width: 100%;
    background-image: url("../../assets/border.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .SlitSplit {
    padding: 1px 25px;
    margin-left: -25px;
    margin-right: -25px;
    position: relative;
    margin-top: -20px;
    margin-bottom: 20px;
    background-color: #E4E4E4;
  }

  .SlitBack {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20%;
  }

  .pseudobtn {
    background-color: #E4E4E4;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 1rem;
    z-index: 201;
    height: 2.5em;
    line-height: 1.6;
    border-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-width: 2pt !important;
    text-align: left !important;
    font-weight: bold;
    overflow: hidden;
    margin-bottom: 10px;
    justify-content: unset !important;
  }

  .pseudobtn:hover {
    background-color: #dedede;

  }

  .pseudobtn.active {
    width: 140%;
    background-color: white;
  }

  .menubutton-icon-wrapper.pseudobuttonique {
    margin-right: 10px;
  }

  .menubutton-icon.orange {
    filter: hue-rotate(182deg)
  }

  .pseudobtn {
    background-color: #E4E4E4;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    white-space: wrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 1rem;
    min-height: 2.5em;
    line-height: 1.6;
    border-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-width: 2pt !important;
    text-align: left !important;
    font-weight: bold;
    margin-bottom: 10px !important;
    justify-content: unset !important;
    transition: background-color .3s ease;
  }



  .pseudobtn.lvl1.active {
    background-color: var(--PooltechnikOrangeLight) !important
  }

  .pseudobtn.lvl2.active {
    background-color: var(--PooltechnikOrangeLight) !important
  }

  .pseudobtn.lvl3.active {
    background-color: var(--PooltechnikOrangeLight) !important;
    color: white
  }

  .pseudobtn_backdrop {
    position: absolute;
    width: 20px;
    height: 100%;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .pseudobtn.has-backdrop {
    margin-left: 5px;
    z-index: 200;
    position: absolute;
    width: calc(100% - 5px);
  }


  .pseudobtns,
  .pseudobtn_main {
    position: relative;
    width: 100%;
    min-height: 2.5em;
  }

  .pseudobtn_backdrop.lvl1 {
    background-color: var(--PooltechnikOrange)
  }

  .pseudobtn_backdrop.lvl2 {
    background-color: var(--PooltechnikOrange)
  }

  .pseudobtn_backdrop.lvl3 {
    background-color: var(--PooltechnikOrange)
  }

  .pseudobtn_backdrop.lvl4 {
    background-color: var(--PooltechnikOrange)
  }

  .pseudobtn:hover {
    background-color: #dedede;
  }

  .pseudobtn:active {
    background-color: #d7d7d7;
  }

  .pseudobuttonique {
    margin-right: 10px !important;
  }

  /*.MenuAll {
    min-height: 600px
  }*/
</style>