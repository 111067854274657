<template>
  <a :href="'tel:+420'+number" class="BzOb_Infoline is-hidden-tablet-only is-hidden-widescreen-only is-hidden-desktop-only" :style="cssProps">
    <img src="../../assets/infoline_base.svg" class="noselect" v-if="theme == 'base'"/>
    <img src="../../assets/infoline_white.svg" class="noselect" v-if="theme == 'white'"/>
    <div class="BzObLd_Counter2"></div>
    <div class="BzObIl_Text is-hidden-mobile " :class="{collapsed: collapsed}">
      <p class="BzObIlTx_1 noselect">
        <transition name="zoomr_f" mode="out-in">
          <p v-if="change">{{ changing }}</p>
          <div v-else>{{ changing }}</div>
        </transition>
      </p>
      <p class="BzObIlTx_2 noselect"><a :href="tel">{{number}}</a></p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'NavigationInfoline',
  props: {
    number: String,
    theme: String,
    collapsed: Boolean
  },
  data() {
    return {
      changing: 'od 8:00 do 16:00',
      change: true
    }
  },
  computed: {
    tel() {
      return "tel: +420" + (this.number.split(" ").join(""))
    },
    cssProps() {
      if (this.theme == "base") {
        return { '--text': "black" }
      }
      else
      {
        return { '--text': "white" }
      }
    }
  },
  mounted() {
    setInterval(() => {
      if (this.change)
        this.changing = 'pondělí až pátek';
      else
        this.changing = 'od 8:00 do 16:00';

      this.change = !this.change;
    }, 4000);
  }
}
</script>

<style scoped>
img {
  height: 48px;
  pointer-events: none;
}
.BzOb_Infoline
{
  display: flex;
  margin-right: -10px;
  position:relative
}
.BzObIlTx_1 {
  font-size: var(--UICompletor);
  color: var(--text);
  margin-top: 4px;
  pointer-events: none;
  margin-bottom: -10px;
}
.BzObIlTx_2 {
  font-size: var(--UIMajor);
  margin-top: 3px;
  font-weight: 900;
  color: var(--text);
}
.BzObIlTx_2 a:link,.BzObIlTx_2 a:visited,.BzObIlTx_2 a:active
{
  color: var(--text)!important;
}
.BzObIlTx_2 a:hover {
  color: var(--PooltechnikBlue)!important;
}
.BzObIl_Text {
  margin-left: 10px;
  text-wrap: none;
    white-space: nowrap;
  width: 120px;
  transition: all 0.5s ease-in-out;
}
.BzObIl_Text.collapsed
{
  width: 0px;
  opacity: 0%;
  pointer-events: none;
}
@media screen and (max-width:768px) {
  img {
  height: 36px;
  pointer-events: none;
}
}
</style>