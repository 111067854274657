<template>
  <div class="back" v-if="loaded">
    <div class="BzObMp_CarouselBody noselect">
      <div class="BzObMpCb_Main" @click="gotogo">
        <transition :name="animation" mode="out-in">
          <div class="BzObMpCbMn_Image" :key="current" v-if="!(getWindow() <= '768' && carousel[current.type] == 'normal')"
            :style="{ backgroundImage: 'url('+(dynamicBack()) + ')' }">
            <div class="BzObMp_TagSale"
              v-if="carousel[current].type != 'fullbanner' && carousel[current].type != 'normal-neo'">
              <router-link to="vernostni-program/"><img src="../../assets/tag.svg" /></router-link>
            </div>

            <div class="BzObMp_TagParent" @click="animate" v-if="carousel[current].type=='default'">
              <p :class="carousel[current].tagposition" class="BzObMp_Tag">
                {{carousel[current].tagline}}
              </p>
            </div>
            <div class="BzObMp_TagParent" v-if="carousel[current].type == 'normal'">
              <div class="columns is-marginless is-paddingless is-vcentered fh">
                <div class="column">
                  <div class="BzObMpTp_Tag">
                    <div class="BzObMpTp_TagOrange">

                    </div>
                    <div class="BzObMpTp_Body " v-html="carousel[current].tagline">

                    </div>
                  </div>
                  <ul class="BzObMpTp_Bullets">
                    <li class="BzObMpTp_Bullet" v-for="(bullet, index) in JSON.parse(carousel[current].bullets)"
                      :key="index">+ {{bullet}}</li>
                  </ul>
                </div>
                <div class="column is-narrow BzObTp_ProductWrapper is-hidden-mobile"
                  v-for="(product, index) in JSON.parse(carousel[current].products)" :key="index">
                  <router-link tag="div" :to="product.link" class="BzObTp_Product has-text-centered">
                    <div class="BzObTpPd_View"  :class="carousel[current].tagposition" :style="{ backgroundImage: 'url('+get_image(product.image)+')' }" />
                    <div class="BzObMpTp_Tag Small">
                      <div class="BzObMpTp_Body Small">
                        <p class="BzObMp_ProductName">{{product.name}}</p>
                        <p class="BzObMp_ProductPrice">{{product.price}} Kč</p>
                      </div>
                    </div>
                  </router-link>

                </div>
              </div>
            </div>
            <div class="BzObMp_TagParent Neo" v-if="carousel[current].type == 'normal-neo'">
              <div class="BzObMpTpNeo_Sub" v-html="JSON.parse(carousel[current].bullets)[0]"></div>
              <div class="BzObMpTpNeo_Head"
                v-html="carousel[current].tagline + ' <span class=\'BzObMpTpNeoH_Bl\'>❄</span>'"></div>
              <div class="columns is-marginless is-paddingless is-vcentered is-centered BzObMpTpNeo_Products">
                <div class="column is-narrow mobileneo"
                  v-for="(product, index) in JSON.parse(carousel[current].products)" :key="index">
                  <router-link tag="div" :to="product.link" class="BzObTp_Product has-text-centered">
                    <div class="columns is-end is-variable is-0">
                      <div class="column is-narrow">
                        <div class="BzObTpPdNeo_View"
                          :style="{ backgroundImage: 'url(' + get_image(product.image) + ')'}" :class="carousel[current].tagposition" />
                      </div>
                      <div class="column">
                        <div class="BzObMpTp_Tag SmallNeo">
                          <div class="BzObMpTpNeo_Body SmallNeo">
                            <p class="BzObMpNeo_ProductName">{{ product.name }}</p>
                            <p class="BzObMpNeo_ProductPrice">{{ product.price }} Kč</p>
                          </div>
                        </div>
                      </div>
                    </div>


                  </router-link>

                </div>
              </div>
              <div class="BzObSnowerlay"></div>
            </div>
          </div>
        </transition>
      </div>
      <div class="BzObMpCb_NavbarMobile_Wrapper is-hidden-tablet" draggable="false">
        <div class="BzObMpCb_NavbarMobile">
          <div v-for="(slide, index) in carousel" :key="index" class="">
            <div class="BzObMpCbNb_Item" :class="{ active: carousel[index].active }" @click="show_item(index)">
              <div class="BzObMpCbNbIt_CursorMobile"></div>
            </div>
          </div>
        </div>

        {{carousel[current].title}}
      </div>
      <div class="BzObMpCb_Navbar is-hidden-mobile" draggable="false">
        <div v-for="(slide, index) in carousel" :key="index">
          <div class="BzObMpCbNb_Item"  v-if="!(getWindow() <= '768' && carousel[current.type] == 'normal')" :class="{ active: carousel[index].active }" @click="show_item(index)">
            <div style="width:100%">{{slide.title}}</div>
            <div class="BzObMpCbNbIt_Cursor"></div>
          </div>
          <div class="BzObMp_DottedDivider" v-if="index != carousel.length - 1"></div>
        </div>
      </div>
    </div>

    <b-loading :is-full-page="true" v-model="loading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: 'MainCarousel',
    props: {
      animated: Boolean,
      animationDelay: Number
    },

    methods: {
      gotogo: function () {
        if (this.carousel[this.current].type == 'fullbanner') {
          this.$router.push(this.carousel[this.current].link);
        }
      },
      getWindow: function() { return window.innerWidth; },
      dynamicBack: function() {
        if(window.innerWidth <= 768)
        
        return this.carousel[this.current].imagemobile;
        else
        return this.carousel[this.current].image;
      },
      show_item: function (id) {
        this.animation = "zoom_f";

        if (id > this.current) {
          this.animation = "zoomr_f";
        }

        this.carousel.forEach(element => {
          element.active = false;
        });
        this.current = id;
        this.carousel[id].active = true;
      },
      get_image: function (url) {
        // console.log(url);
        if (url != undefined)
          return require('../../assets/carousel/' + url);
      },
      load_from_fujin: function () {

        var self = this;
        axios.get(
            "https://vespotok.com/bazenyobchod/kernelc.fujin?a=a&c=get_slider(1)&e=/apps/persephone/cgi/mainpage")
          .then(function (response) {
            if(window.innerWidth <= 768)
            {

            self.carousel = response.data.data.slides.filter((slide) => slide.hidden != 'Y' && slide.type != 'normal');
            }
            else
            {
            self.carousel = response.data.data.slides.filter((slide) => slide.hidden != 'Y');

            }
            self.animate();
            self.loaded = true;
            self.loading = false;
          });
      },
      animate: async function () {
        if (this.animated != null && this.animated) {
          let delay_local = 1000;
          if (this.animationDelay != null) {
            delay_local = this.animationDelay;
          }
          for (var i = 0; i < this.carousel.length; i++) {
            this.show_item(i);

            await new Promise(s => setTimeout(s, delay_local));
          }
          this.animate();
        }

      }
    },
    data() {
      return {
        loaded: true,
        current: 0,
        loading: true,
        carousel: [{
          type: "default",
          title: "",
          tagline: "",
          image: "https://bazeny-obchod.cz/carousel/arcana.jpg",
          tagposition: "top-left",
          active: true
        }, ],
        animation: "zoomr_f"
      }

    },
    
    created() {
      this.load_from_fujin();
    }
  }
</script>
<style>
  .BzObMpTpNeoH_Bl {
    color: var(--PooltechnikBlue);
  }

  .columns.is-end {
    align-items: flex-end;
  }
</style>
<style scoped>
  .BzObMpTpNeo_Products {
    position: absolute;
    z-index: 20;
    width: 100%;
    bottom: 0;
    height: auto;
  }

  .BzObSnowerlay {
    background-image: url('http://www.bazeny-obchod.cz/carousel/snowerlay.png');
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -30px;
    margin-left: -20px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
  }

  .BzObTpPd_View {
    height: 200px;
    width: 150px;
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: -20px;
    margin-left: -8px;
  }

  .BzObTpPdNeo_View {
    height: 290px;
    width: 130px;
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .BzObTp_Product {
    transition: all 0.1s ease;
    text-align: center;
  }

  .BzObTp_Product:hover {
    cursor: pointer;
    margin-bottom: 20px;
  }

  .BzObTp_ProductWrapper:nth-child(2n) {
    padding-top: 60px;
  }

  .BzObTp_ProductWrapper:nth-child(2n+1) {
    padding-bottom: 60px;
  }

  .BzObMp_ProductPrice {
    white-space: nowrap;
    font-size: var(--UIMinor);
    line-height: var(--UIMinor);
    color: var(--PooltechnikOrange)
  }

  .BzObMp_ProductName {
    font-size: var(--UICompletor);
    line-height: var(--UICompletor);
    font-weight: 200;
  }

  .BzObMpNeo_ProductPrice {
    white-space: nowrap;
    font-size: var(--UIMinor);
    line-height: var(--UIMinor);
  }

  .BzObMpNeo_ProductName {
    font-size: calc(var(--UIMinor) + 2px);
    line-height: calc(var(--UIMinor) + 2px);
    font-weight: 800;
  }

  .BzObMpTp_Bullet {
    font-size: var(--UIMinor);
    color: white;
    font-weight: bold;
    line-height: var(--UIMinor);
    margin-bottom: 0px;
    text-shadow: 2px -1px 11px rgba(0, 0, 0, 1);
  }

  .BzObMpTp_Bullets {
    margin-top: 20px;
  }

  .fh {
    height: 100%;
  }

  .BzObMpTp_Tag {
    margin-left: 10px;
    position: relative;

    display: flex;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .BzObMpTp_Tag.Small {
        justify-content: center;
  }
  .BzObMpTp_TagOrange {
    background: var(--PooltechnikOrangeDark);
    margin-left: -6px;
    display: flex;
    width: 20px;
    border-bottom-left-radius: 20px;
  }

  .BzObMpTp_Body {
    border-bottom-left-radius: 20px;
    background: #333333;
    padding: 10px 25px;
    margin-left: -15px;
    color: white;
    font-size: calc(var(--UIMajor) + 4px);
    line-height: calc(var(--UIMajor) + 4px);
    font-weight: bold;
    border-top-right-radius: 20px;
  }

  .BzObMpTpNeo_Body {
    border-bottom-left-radius: 20px;
    background: var(--PooltechnikBlue);
    padding: 10px 25px;
    margin-left: -15px;
    color: white;
    font-size: calc(var(--UIMajor) + 4px);
    line-height: calc(var(--UIMajor) + 4px);
    font-weight: bold;
    border-top-right-radius: 20px;
  }

  .BzObMpTpNeo_Head {
    border-bottom-right-radius: 20px;
    background: white;
    padding: 15px 30px;
    color: #333333;
    font-size: calc(var(--UIMajor) + 20px);
    line-height: calc(var(--UIMajor) + 20px);
    font-weight: bold;
    border-top-left-radius: 20px;
    top: 60px;
    left: 40px;
    position: absolute;
    width: auto;
  }

  .BzObMpTpNeo_Sub {
    border-bottom-right-radius: 20px;
    background: var(--PooltechnikBlue);
    padding: 16px 30px;
    padding-left: 58px;
    color: white;
    font-size: calc(var(--UIMajor));
    line-height: calc(var(--UIMajor));
    font-weight: bold;
    border-top-left-radius: 20px;
    top: 60px;
    left: 524px;
    position: absolute;
    width: auto;
  }

  @media (max-width: 768px) {
    .BzObMpTpNeo_Head {
      font-size: calc(var(--UIMajor));
      line-height: calc(var(--UIMajor));
      font-weight: bold;
      border-top-left-radius: 20px;
      top: 60px;
      left: 55px;
      position: absolute;
      width: auto;
    }

    .BzObMpTpNeo_Sub {
      padding-left: 30px;
      top: 120px;
      left: 40px;
      font-size: calc(var(--UIMinor));

    }
  }

  .BzObMpTp_Body.Small {
    padding: 8px 10px;

    min-width: 150px;
  }

  .BzObTpPd_View.fat {
    width: 180px;
  }

  .BzObMpTpNeo_Body.SmallNeo {

    padding: 15px 25px;
    min-width: 150px;
    margin-left: -100px;
    padding-left: 90px;
    margin-top: -98px;
    margin-bottom: 90px;
    z-index: -1;
  }


  .BzObMp_Button {
    position: absolute;
    right: 40px;
    height: 100%;
    padding-top: 390px;
  }

  .BzObMp_TagSale {
    z-index: 60;
    position: absolute;
    width: 160px;
    padding-top: 30px;
    height: 100%;
    right: 0;
  }

  .BzObMp_TagParent {
    position: relative;
    width: 100%;
    padding-left: 20px;
    height: 100%;
    padding-right: 100px;
  }

  .BzObMp_TagParent.Neo {
    padding-right: 20px;

  }

  .BzObMp_Tag {
    color: white;
    font-style: italic;

    line-height: 4.7rem;
    font-size: 4.5rem;
    font-weight: 800;
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.77);
  }

  .BzObMp_Tag.top-left {}

  .BzObMp_Tag.top-right {
    text-align: right;
  }

  .BzObMp_Tag.bottom-left {
    position: absolute;
    bottom: 80px;
    right: 70px;
    left: 70px;
  }

  .BzObMp_Tag.bottom-right {
    text-align: right;
    position: absolute;
    bottom: 80px;
    right: 70px;
    left: 70px;
  }

  .BzObMpCbMn_Image {
    width: 100%;
    height: calc(100% + 40px);
    margin-top: -20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .BzObMp_DottedDivider {
    height: 2px;
    width: calc(100% - 50px);
    opacity: 0.4;
    margin-left: 25px;

    background-image: url('../../assets/dashed_white.svg');
  }

  .BzObMp_CarouselBody {
    min-height: 442px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    display: flex;
    overflow: hidden;
  }

  .BzObMpCb_NavbarMobile_Wrapper {
    width: 100%;
    background-color: var(--PooltechnikOrangeDark);
    padding: 30px;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    position: relative;
    border-bottom-right-radius: 25px;
    font-weight: bold;
  }

  .BzObMpCb_NavbarMobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    top: 0;
    margin-left: -30px;
    align-items: stretch;
  }

  .BzObMpCb_NavbarMobile>div {
    flex: 1;
  }

  .BzObMpCb_Main {
    width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: inset 0px 0px 35px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 35px -18px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 35px -18px rgba(0, 0, 0, 0.75);
    background: gray;
  }

  .BzObMpCb_Navbar {
    background: var(--PooltechnikOrangeDark);
    width: 260px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 442px;

    flex-flow: wrap;
    justify-content: center;
  }



  .BzObMpCbNb_Item {
    font-size: var(--UIMajor);
    line-height: var(--UIMajor);
    font-weight: bold;
    color: white;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    transition: all 0.2s;

    width: 210px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .BzObMp_CarouselBody {
      min-height: 0px;
    }

    .BzObMpCb_Navbar {
      width: 100%;
      flex-direction: row;
      flex-flow: unset;
      height: 300px;
    }

    .BzObMp_CarouselBody {
      flex-direction: column;
      border-bottom-right-radius: 0px;
    }

    .BzObMpCb_Main {

      height: 600px;
      border-bottom-right-radius: 0px;
    }

    .BzObMpTp_Tag {
      margin-left: 95px
    }

    .BzObTpPdNeo_View {
      height: 177px;
      width: 53px;
    }

    .mobileneo {
      margin-bottom: -180px;

    }

    .mobileneo:nth-child(2n) {
      margin-left: 95px;
    }

    .BzObMpTpNeo_Products {
      bottom: 155px;
      height: auto;
    }

    .BzObMpTpNeo_Body {
      background: #222;
    }

    .BzObMpCbNb_Item {
      padding: 0px;
      font-size: var(--UIMinor);
    }

    .BzObMp_Tag {
      font-size: var(--HeaderMicro);
      line-height: var(--HeaderMicro);
    }

    .BzObMp_TagParent {
      padding: 50px 30px;
    }

    .BzObMp_Tag.bottom-left {
      bottom: 50px;
      right: 30px;
      left: 30px;
    }

    .BzObMp_Tag.bottom-right {
      bottom: 50px;
      right: 30px;
      left: 30px;
    }
  }

  .BzObMpCbNbIt_Cursor {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    position: absolute;
    left: 0px;
    border-right: 30px solid transparent;
    transition: all 0.2s ease;
  }

  .BzObMpCbNb_Item.active .BzObMpCbNbIt_Cursor {
    left: -28px;
    border-right: 30px solid var(--PooltechnikOrangeDark);
  }

  .BzObMpCbNb_Item:hover .BzObMpCbNbIt_Cursor {
    left: -20px;
    border-right: 30px solid var(--PooltechnikOrangeDark);
  }

  .BzObMpCbNbIt_CursorMobile {
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-bottom: 30px solid transparent;
    position: absolute;
    top: 0px;
    border-right: 30px solid transparent;
    transition: all 0.2s ease;
  }

  .BzObMpCbNb_Item.active .BzObMpCbNbIt_CursorMobile {
    top: -30px;
    border-bottom: 30px solid var(--PooltechnikOrangeDark);
  }

  .BzObMpCbNb_Item:hover {
    margin-left: 10px;
  }

  .BzObMpCbNb_Item.active:hover {
    margin-left: 0px;
  }

  .BzObMpCbNb_Item.active:hover .BzObMpCbNbIt_Cursor {
    left: -30px;
    border-right: 30px solid var(--PooltechnikOrangeDark);
  }
</style>