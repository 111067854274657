<template>
  <div v-if="isOpen">
    <div class="BzObMm_Menu" :class="{closing: closing}" ref="mainmenubody">
      <h4 v-if="last_category_computed && last_category_computed.nazev !== ''">{{ last_category_computed.nazev }}</h4>
      <template v-if="current_level > 1" >
        <div class="BzObMmMn_Link category"
             @click="() => {
                $router.push(last_category_computed.seo_nazev);
                close()
             }">
          <div class="icon">
            <b-icon icon="view-dashboard"></b-icon>
          </div>
          <div class="name">
            Přejít do kategorie
          </div>
        </div>
        <div class="BzObMmMn_Link all"
             @click="() => {
                current_level = 1;
                load_subcategories()
                current_level = 1;
             }">
          <div class="icon">
            <b-icon icon="home"></b-icon>
          </div>
          <div class="name">
            Všechny kategorie
          </div>
        </div>
        <div class="BzObMmMn_Link back"
             @click="load_subcategories(last_category_computed, true)">
          <div class="icon">
            <b-icon icon="chevron-left"></b-icon>
          </div>
          <div class="name">
            Zpět
          </div>
        </div>
      </template>
      <div class="BzObMmMn_Link" v-for="(link, l) in links" :key="l" @click="() => {
        if(link.podkategorie === 'true')
          {
            load_subcategories(link);
          }
        else
          {
            $router.push(link.seo_nazev);
            close()
          }
      }" :class="{
        'no-icon': !link.ikonka,
        'active': path && Array.isArray(path) && path.map((el) => el.id).includes(link.id)
      }">
        <div class="icon" v-if="link.ikonka">
          <img :src="'https://www.bazeny-obchod.cz'+link.ikonka" class="menubutton-icon"/>
        </div>
        <div class="name">
          {{ link.nazev }}
        </div>
        <div v-if="link.podkategorie === 'true'" class="append">
          <b-icon icon="chevron-right"></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'MainMenu',
  components: {},
  props: {
    modelValue: Boolean,
    path: Array
  },
  emits: ['update:modelValue', 'closingMenu'],
  methods: {
    close: function () {

      this.$emit('closingMenu');
      this.closing = true;
      setTimeout(() =>
          this.isOpen = false, 1000);
      setTimeout(() => {
        this.closing = false

      }, 1500);
    },
    closingMenu: function () {
      this.$emit('closingMenu');
    },
    load_subcategories: function (category = null, descend = false) {
      const self = this;
      if (category)
        this.current_id = category.id ?? 0;
      if (descend)
        this.current_level--;
      else {
        this.current_level++
        this.last_category[this.current_level] = category;
      }

      let url = "https://www.bazeny-obchod.cz/apieshop/podkategorie/";
      if (category && !descend)
        url += category.id
      if (descend && this.last_category[this.current_level])
        url += this.last_category[this.current_level].id

      if (this.cache[url]) {
        this.links = this.cache[url];
      } else {
        axios.get(url)
            .then(function (response) {
              self.links = response.data.kategorie;
              self.cache[url] = response.data.kategorie;
            })
            .catch(function (error) {
              self.$buefy.snackbar.open({
                duration: 5000,
                message: "Stala se chyba v načítání podkategorií: " + error,
                type: 'is-danger',
                position: 'is-bottom-left',
                queue: false,

              })
            });
      }

    },
  },
  mounted() {
    this.load_subcategories();
  },
  computed: {
    last_category_computed() {
      return this.last_category[this.current_level];
    },
    isOpen: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data() {
    return {
      closing: false,
      current_id: null,
      last_category: {},
      current_level: 0,
      links: [],
      cache: {}
    }
  }
}
</script>

<style scoped lang="scss">

.BzObMmMn_Vertical {
  background-image: url("../../assets/border_v.svg");
  height: calc(60vh);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.ScVw_Menu {
  overflow: scroll;
}

.BzObMm_Menu.closing {
  animation-delay: 0s;
  animation: scale-out-center 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.BzObMm_Backdrop.closing {
  animation-delay: 0.3s !important;
  animation: scale-out-vertical 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.BzObMmMn_Close {
  position: absolute;
  top: 20px;
  right: 30px;

  @media screen and (max-width: 768px) {
    right: calc(50% - 30px);
  }
}

.ScVw_Padding {
}

.ScVw_Wrapper {
  height: 100%;
  position: relative;
}

.ScrollView {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
}

.BzObMmMnMpDp_Menu {
  margin-top: 20px;
  margin-bottom: 20px;
}

.BzObMmMnMpDp_Title {
  font-size: var(--UIMajor);
  font-weight: 900;
  color: var(--PooltechnikBlue);
  text-transform: uppercase;
}

.fh {
  height: 100%;
}

.BzObMmMnIp_Center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.BzObMmMnIpCn_Image {
  width: 100%;
}

.BzObMmMn_MenuPortion {
  height: 100%;
  width: calc(100% - 33%);
  background-image: url("../../assets/bubbles.svg");
  background-position: bottom right;
  background-size: 180px;
  background-repeat: no-repeat;
}

.BzObMmMn_ImagePortion {
  width: 33%;
  background-image: url("../../assets/mainmenu.jpg");
  background-position: right center;
  border-top-left-radius: 50px;
  position: relative;
}

.BzObMm_Backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9000;
  backdrop-filter: blur(10px);
  animation: scale-up-ver-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.BzObMm_Menu {
  position: fixed;
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: white;
  z-index: 9001;
  animation: scale-in-center 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 0.2s;
  display: flex;
  gap: 8px;

  flex-direction: column;
  @media screen and (max-width: 768px) {
    height: calc(100% - 73px);
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(252, 252, 252);
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    padding: 15px;
    overflow: scroll;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .BzObMmMn_Link {
    padding: 8px 12px;
    background: #e4e4e4;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    gap: 12px;

    &.active {
      background-color: var(--PooltechnikBlueLight);
    }

    .name {
      width: 100%;
      font-weight: bold;
    }

    &.back {
      background-color: var(--PooltechnikOrange);
      color: white;
    }
    &.all {
      background-color: var(--PooltechnikOrangeLight);
      color: var(--PooltechnikOrangeDark);
    }
    &.category {
      background-color: var(--PooltechnikBlueLight);
      color: var(--PooltechnikBlue);
    }

    &.no-icon {
      border-left: 3px solid var(--PooltechnikOrange)

    }
    &.active.no-icon {
      border-left: 3px solid var(--PooltechnikBlue)
    }


  }

}

.BzObMmMn_MenuPortion {
  width: calc(100% - 33%);

  @media screen and (max-width: 768px) {
    width: 100%;
    background: none;
  }
}

.ScrollView {
  width: 100%;
  position: relative;
  height: 100%;
  display: block;
  overflow: unset;
}

.ScVw_Padding {
  display: none;
}

/* Animation Keyframes */

@keyframes scale-out-vertical {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(0.4);
    opacity: 0;
  }
}

@keyframes scale-out-center {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes scale-in-center {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-up-ver-center {
  0% {
    transform: scaleX(0.4);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

</style>