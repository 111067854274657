import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import VueProgressBar from 'vue-progressbar'
import './assets/buefy.scss'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css'
import axios from 'axios'
import VueMeta from 'vue-meta'
import VueSchemaMicrodata from 'vue-schema-microdata'
import vueDebounce from 'vue-debounce'
import vueScrollBehavior from 'vue-scroll-behavior'
import CKEditor from '@ckeditor/ckeditor5-vue2';


Vue.use(CKEditor);
Vue.use(vueDebounce)
import VueGtag from "vue-gtag";
import CookieConsentVue from "@/plugins/CookieConsentVue";

Vue.use(VueGtag, {
    config: {id: "G-NTKQWENKNY"}
});
Vue.use(VueSchemaMicrodata)
Vue.use(VueMeta)
Vue.use(CookieConsentVue, {
    // your config. goes here (required)
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom right",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {},
        marketing: {}
    },
    language: {
        default: "en",
        autoDetect: "browser",
        translations: {
            en: {
                consentModal: {
                    title: "Používáme cookies",
                    description: 'Pro základní funkčnost, zpříjemnění používání webu, analytické účely a v případě udělení souhlasu také pro účely cílení reklamy využíváme soubory cookies. Nastavení vlastních preferencí cookies můžete kdykoli upravit odkazem ve spodní části stránek &bdquo;<button type="button" data-cc="c-settings" class="cc-link">Nastavení cookies</button>&ldquo;.',
                    acceptAllBtn: "Přijmout vše",
                    acceptNecessaryBtn: "Odmítnout vše",
                    showPreferencesBtn: "Nastavení",
                    // footer: "<a href=\"#link\">Privacy Policy</a>\n<a href=\"#link\">Terms and conditions</a>"
                },
                preferencesModal: {
                    title: "Nastavení Cookies",
                    acceptAllBtn: "Přijmout vše",
                    acceptNecessaryBtn: "Odmítnout vše",
                    savePreferencesBtn: "Uložit nastavení",
                    closeIconLabel: "Zavřít",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Nezbytné cookies",
                            description: "Tyto soubory cookie jsou nezbytné pro správné fungování mých webových stránek. Bez těchto cookies by web nefungoval správně.",
                            linkedCategory: "necessary"
                        }, {
                            title: "Analytics Cookies",
                            description: "Tyto soubory cookie umožňují webu zapamatovat si volby, které jste provedli v minulosti. Data jsou následně anonymizována, nejedná se tudíž o osobní údaje. Tím můžeme zlepšovat naše webové stránky.",
                            linkedCategory: "analytics"
                        }, {
                            title: "Reklamní Cookies",
                            description: "Tyto soubory cookies shromažďují informace o tom, jak webové stránky používáte, které stránky a kategorie jste navštívili a na které odkazy jste klikli. Všechna data jsou anonymizována a nelze je použít k vaší identifikaci. Díky těmto datům můžeme nabídnout relevantní produkty a služby na míru.",
                            linkedCategory: "marketing"
                        }
                    ]
                }
            }
        }
    },
    onChange: ({cookie}) => {
        window.souhlasmarketing = (cookie.categories.includes('marketing')) ? 1 : 0;
    },
    onConsent: ({cookie}) => {
        window.souhlasmarketing = (cookie.categories.includes('marketing')) ? 1 : 0;
    }

})
Vue.use(Buefy, {
    customIconPacks: {
        'mdi': {
            sizes: {
                'default': 'mdi-20px',
                'is-small': 'mdi-20px',
                'is-medium': 'mdi-20px',
                'is-large': 'mdi-24px'
            }
        }
    }
})
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.withCredentials = true;
Vue.filter('stripHTML', function (value) {
    const div = document.createElement('div')
    div.innerHTML = value
    const text = div.textContent || div.innerText || ''
    return text
});
//Vue.component('cookie-consent', CookieConsent);
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(vueScrollBehavior, {
    router: router,    // The router instance
    maxLength: 100,    // Saved history List max length
    ignore: [/\/boo/, /\/zoo/],    // ignore some routes, they will directly scroll to the top
    delay: 0          // Delay by a number of milliseconds
})
Vue.use(VueProgressBar, {
    color: '#D46004',
    failedColor: 'red',
    height: '2px'
})

new Vue({
    router,
    render: h => h(App),
    watch: {
        $route(to) {
            var url = "https://www.bazeny-obchod.cz/apieshop/urls" + to.path;
            this.getDynamicRoutes(url, to.path);
            console.log(to)


        }
    },
    created() {
        var url = "https://www.bazeny-obchod.cz/apieshop/urls" + this.$router.currentRoute.path;
        this.getDynamicRoutes(url, this.$router.currentRoute.path);
    },
    methods: {
        getDynamicRoutes(url, path_local) {
            // vstupní pole regexů
            let regexy = ["/hledani/*"];

            // hledaný string
            let hledanyString = path_local;

            // projdeme všechny regexy a hledáme shodu
            let nalezeno = false;
            for (let i = 0; i < regexy.length; i++) {
                // nahradíme * regulárním výrazem .* a zpracujeme regex jako vzor
                let vzor = new RegExp(regexy[i].replace("*", ".*"));
                if (vzor.test(hledanyString)) {
                    // pokud je nalezena shoda, tak nastavíme nalezeno na true a ukončíme cyklus
                    console.log("Nalezeno:", regexy[i]);
                    nalezeno = true;
                    break;
                }
            }
            if (nalezeno) {
                return null;
            }


            var self = this;
            axios
                .get(url)
                .then(function (response) {
                    let track = true;
                    let route = response.data;
                    var component = "./views/CategoryView.vue";
                    var title = "Titulek";
                    var name_r = "BazényObchod.cz pro vás načítají obsah.";
                    var icon_collapsed = true;
                    var additional = {};
                    var profile = "pageful";
                    switch (route.typ) {
                        case "kategorie":
                            track = false;
                            if (route.uid == "") {
                                profile = "main";
                                component = "./views/F04Page.vue";
                                title = "404";
                                name_r = "404";
                            } else {
                                if (route.uid == 1034) {
                                    component = "./views/ConfiguratorPage.vue";
                                    title = "Konfigurátor";
                                    profile = "main";
                                    name_r = "Konfigurátor";

                                } else {
                                    component = "./views/CategoryView.vue";
                                    title = "Načítám jméno kategorie...";
                                    name_r = "Category";
                                    additional = {
                                        stranka: route.stranka,
                                        znacka: route.znacka
                                    }
                                }
                            }
                            break;
                        case "zbozi":
                            component = "./views/ProductView.vue";
                            title = "Načítám jméno produktu...";
                            name_r = "Product";

                            break;
                        case "specialni":
                            switch (route.uid) {
                                case "kosik":
                                    component = "./views/CartView.vue";
                                    title = "Košík";
                                    name_r = "Košík";
                                    profile = "main";
                                    icon_collapsed = false;
                                    break;
                                case "login":
                                    component = "./views/LoginPage.vue";
                                    title = "Přihlášení";
                                    name_r = "Přihlášení";
                                    profile = "login";
                                    icon_collapsed = false;
                                    break;
                                case "heslo":
                                    component = "./views/PasswordPage.vue";
                                    title = "Zapomenuté heslo";
                                    name_r = "Zapomenuté heslo";
                                    profile = "login";
                                    icon_collapsed = false;
                                    break;
                                case "uzivatel":
                                    component = "./views/SettingsPage.vue";
                                    title = "Nastavení uživatele";
                                    name_r = "Nastavení uživatele";
                                    profile = "login";
                                    icon_collapsed = false;
                                    break;
                                case "registrace":
                                    component = "./views/RegisterPage.vue";
                                    title = "Registrace uživatele";
                                    name_r = "Registrace uživatele";
                                    profile = "login";
                                    icon_collapsed = false;
                                    break;
                            }
                            break;
                        case "texty":
                            component = "./views/TextsPage.vue";
                            title = "Načítám nadpis stránky...";
                            name_r = "Načítám nadpis stránky...";
                            profile = "main";

                            break;
                        case "stranka":
                            component = "./views/TextsPage.vue";
                            title = "Načítám nadpis stránky...";
                            name_r = "Načítám nadpis stránky...";
                            profile = "main";

                            break;
                    }

                    let newRoute = {
                        path: `${path_local}`,
                        component: () => import(`${component}`),
                        name: name_r + Date.now(),
                        props: {
                            uid: route.uid,
                            additional: additional
                        },
                        meta: {
                            title: title,
                            profile: profile,
                            scroll_top: 0,
                            icon_collapsed: icon_collapsed,
                            track: track
                        }
                    }
                    self.$router.addRoute(component, newRoute)
                })

                .catch(err => console.log(err))
        },


    },
}).$mount('#app')