<template>
  <router-link :to="'/slevove-kody'" class="BzOb_SaleTag is-hidden-tablet-only is-hidden-widescreen-only is-hidden-desktop-only" :style="cssProps">
    <img src="../../assets/sale_white.svg" class="noselect" v-if="theme == 'base'"/>
    <img src="../../assets/sale_white.svg" class="noselect" v-if="theme == 'white'"/>
    <div class="BzObSt_Text is-hidden-mobile noselect">
      <p>SLEVOVÉ</p>
      <p>KÓDY</p>
    </div>
    <div class="BzObSt_Text is-hidden-tablet noselect">
        <p>SLEVOVÉ KÓDY</p>
      </div>
  </router-link>
</template>

<script>
export default {
  name: 'NavigationSaleTags',
  props: {
    number: String,
    theme: String,
    collapsed: Boolean
  },
  data() {
    return {
      changing: 'od 8:00 do 16:00',
      change: true
    }
  },
  computed: {
    tel() {
      return "tel: +420" + (this.number.split(" ").join(""))
    },
    cssProps() {
      if (this.theme == "base") {
        return { '--text': "black" }
      }
      else
      {
        return { '--text': "white" }
      }
    }
  },
  mounted() {
    setInterval(() => {
      if (this.change)
        this.changing = 'pondělí až pátek';
      else
        this.changing = 'od 8:00 do 16:00';

      this.change = !this.change;
    }, 4000);
  }
}
</script>

<style scoped>
img {
  height: 48px;
  pointer-events: none;
}
.BzOb_SaleTag
{
  background-color: var(--PooltechnikOrange);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 15px;
  padding-left: 5px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position:relative;
  transition: background-color 0.2s ease-in-out;
}
.BzOb_SaleTag:hover {
    background-color: #9b5311;
  }

  @media (max-width: 768px) 
  {
    .BzOb_SaleTag {
      border-top-left-radius: 0px!important;
      border-bottom-right-radius: 0px!important;
      justify-content: center;
      padding: 2px 15px;
    }
  }
.BzObSt_Text {
  font-size: var(--UIMajor);
  line-height: var(--UIMajor);
  font-weight: 900;
  color: white;

}
@media screen and (max-width:768px) {
  img {
  height: 32px;
  pointer-events: none;
}
}
</style>