<template>
  <div id="app" :class="get_back">
    <vue-progress-bar></vue-progress-bar>
    <NavigationBar :icon_collapsed="icon_collapsed" :hide="menu_lay" :scrolling="scrolling" :phone_num="phone_num"
      :theme="get_theme" @menu_opening="desktop_menu_open = !desktop_menu_open" :path="path" :logged="logged"
      :user="user" :favorites="favorites.items.length" :comparison="comparison.items.length" :type="get_type"
      :cart="cart" />
    <NavigationBarMobile :favorites="favorites.items.length" :comparison="comparison.items.length" :theme="get_theme"
      :user="user" :window_width="windowWidth" @menu_opening="main_menu = !main_menu" :closeMenu="menu_closing"
      :type="get_type" :cart="cart" v-if="windowWidth <= 768" />
    <div class="SecondaryBackground" :class="get_back_s"></div>
    <div class="BzOb_MenuLay" ref="menulay" @click="declickall" :class="{active:menu_lay}"></div>
    <MainMenu ref="menuChild" :model-value="main_menu" @closingMenu="main_menu = false" :path="path"></MainMenu>
    <transition :name="get_animation" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>

<script>
  import NavigationBar from './components/navbar_components/NavigationBar.vue'
  import NavigationBarMobile from './components/navbar_mobile/NavigationBarMobile.vue'
  import MainMenu from './components/navigation/MainMenu.vue'
  import axios from 'axios'
  const instance = axios.create({
    withCredentials: true
  })
  export default {
    name: 'BazenyObchod.cz',
    components: {
      NavigationBar,
      NavigationBarMobile,
      MainMenu
    },
    props: {
      openMenu1: {
        type: Function
      },
    },
    $refs: {
      menulay: HTMLElement
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
      document.removeEventListener('keydown', this._keyListener);
    },

    created() {
      this._keyListener = function (e) {

        if (e.key === 'Control') {
          this.ctrlpresses++
          if (this.ctrlpresses >= 2) {
            this.candownload = !this.candownload;
            let message = 'Stahování obrázků zakázáno ❌';
            if (this.candownload)
              message = 'Stahování obrázků povoleno 👍';

            this.$buefy.snackbar.open({
              duration: 5000,
              message: '<p>' + message +
                '</p><br><p><b>Nezapomeňte!</b> Obrázky na tomto serveru jsou chráněny autorským zákonem. Jejich další šíření bez souhlasu autora je zakázané!</p>',
              type: 'is-danger',
              position: 'is-bottom',
              queue: true
            })
          }
          setTimeout(() => (this.ctrlpresses = 0), 700)
        }
      };

      document.addEventListener('keydown', this._keyListener.bind(this));

      //if(this.profile == null)

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      this.profile = this.$route.meta.profile;
      this.$Progress.start();
      this.cart_update();
      if (localStorage.favorites) {
        this.favorites = JSON.parse(localStorage.favorites);
      }

      this.user_update();

      if (localStorage.comparison) {
        this.comparison = JSON.parse(localStorage.comparison);
      }
      this.$router.beforeEach((to, from, next) => {
        
        this.$Progress.start();
        this.menu_lay = false;
        document.querySelector('meta[name="theme-color"]').setAttribute("content", this.get_color(to.meta.profile));
        this.current.category = null;

        if (to.meta.track) {
          this.$gtag.pageview(to.path)
        }
        this.current.level = null;
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;
          this.$Progress.parseMeta(meta);
        }
        this.expanded = false;
        next();

        if (to.meta.profile != from.meta.profile)
          this.profile = to.meta.profile;

        if (to.meta.icon_collapsed != from.meta.icon_collapsed)
          this.icon_collapsed = to.meta.icon_collapsed;

        if (to.meta.icon_collapsed == null) {
          this.icon_collapsed = true;
        }
      });
    },
    watch: {
      logged() {
        this.user_update();
      }
    },
    data() {
      return {
        profile: "main",
        menu_open: false,
        main_menu: false,
        menu_lay: false,
        scrolling: false,
        path: null,
        phone_num: "777 752 184",
        logged: false,
        icon_collapsed: true,
        desktop_menu_open: false,
        ctrlpresses: 0,
        candownload: false,
        windowWidth: window.innerWidth,
        cart: {
          items: [],
          gifts: [],
          shippings: [],
          payments: [],
          enabled_pairs: []
        },
        current: {
          category: null,
          level: null
        },
        favorites: {
          /** Jelikož jde localStorage vidět, tak trochu vyděsíme hackery */
          legal_warning: "By observing local storage and possibly studying the innerworkings of BazényObchod.cz ecommerce web presentation, you are breaking the license agreement between POOLTECHNIK s.r.o. and you. This incident may be reported to the Police of Czech Republic and a legal action may be taken between you and POOLTECHNIK s.r.o.",
          items: []
        },
        user: {
          /** Jelikož jde localStorage vidět, tak trochu vyděsíme hackery */
          legal_warning: "By observing local storage and possibly studying the innerworkings of BazényObchod.cz ecommerce web presentation, you are breaking the license agreement between POOLTECHNIK s.r.o. and you. This incident may be reported to the Police of Czech Republic and a legal action may be taken between you and POOLTECHNIK s.r.o.",
          informace: {}
        },
        comparison: {
          /** Jelikož jde localStorage vidět, tak trochu vyděsíme hackery */
          legal_warning: "By observing local storage and possibly studying the innerworkings of BazényObchod.cz ecommerce web presentation, you are breaking the license agreement between POOLTECHNIK s.r.o. and you. This incident may be reported to the Police of Czech Republic and a legal action may be taken between you and POOLTECHNIK s.r.o.",
          items: []
        }
      }
    },
    methods: {
      /**
       * Navigation methods
       */
      menu_closing: function () {
        this.menu_open = false;
      },
      menu_opening: function () {
        this.openMenu1();

      },
      onResize() {
        this.windowWidth = window.innerWidth
      },
      /**
       * Cart manipulation methods
       */
      add_to_cart: function (product, amount = 1, selected_gift = null, poznamka = null) {
        if (product.zakaz_prodej == "1") {
          this.$buefy.dialog.alert({
            title: 'Pozor!',
            message: 'Tento produkt nelze v tuto chvíli objednat. Omlouváme se.',
            type: 'is-danger',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        } else {
          var self = this;
          var real_gift = null;
          if (selected_gift == null) {
            if (product.darky != undefined && product.darky[0] != null) {
              real_gift = product.darky[0];
            }
            if (product.id_darku != "0") {
              real_gift = product.id_darku;
            }
          }
          instance.post("https://www.bazeny-obchod.cz/apieshop/kosik-pridej/" + product.id, {
            pocet: amount,
            darek: real_gift,
            poznamka: poznamka
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.data.ok != null) {
              self.$buefy.snackbar.open({
                message: 'Produkt ' + product.nazev + ' byl přidán do košíku.',
                type: 'is-primary',
                position: 'is-bottom',
                actionText: 'Do košíku',
                indefinite: false,
                onAction: () => {
                  this.$router.push("/kosik");
                }
              });
              self.cart_update();
            } else {
              this.$buefy.snackbar.open({
                message: 'Přidání do košíku se nepovedlo.',
                type: 'is-danger',
                indefinite: false
              });
            }
          });
        }
      },
      remove_from_cart: function (item_id) {
        var self = this;
        instance.get("https://www.bazeny-obchod.cz/apieshop/kosik-odeber/" + item_id, {

          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        }).then(() => {
          self.$buefy.snackbar.open({
            message: 'Z košíku byl odebrán produkt.',
            type: 'is-danger',
            indefinite: false
          });
          self.cart_update();
        });
      },
      change_cart_amount: function (item_id, amount) {
        var arr = this.cart.items;
        var new_amount = 0;
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].id == item_id) {
            switch (amount) {
              case "+":
                new_amount = arr[i].pocet + 1;
                break;
              case "-":
                if (arr[i].pocet > 1) {
                  new_amount = arr[i].pocet - 1;
                }
                break;
              default:
                new_amount = amount;
                break;
            }

          }
        }
        var self = this;
        instance.post("https://www.bazeny-obchod.cz/apieshop/kosik-prepocitej/" + item_id, {
          pocet: new_amount
        }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(() => {
          self.cart_update();
        });
      },
      cart_update: function () {
        var self = this;
        instance.get("https://www.bazeny-obchod.cz/apieshop/kosik-produkty/", {
          responseType: 'json',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.data.produkty != null) {
            self.cart.items = Object.values(response.data.produkty);
            if (response.data.darky != null)
              self.cart.gifts = Object.values(response.data.darky);
            else
              self.cart.gifts = [];
            self.cart.shippings = Object.values(response.data.dopravy);
            self.cart.payments = Object.values(response.data.platby);
            self.cart.enabled_pairs = Object.values(response.data.provazanost);
          } else {
            self.cart.items = [];
          }
        });
      },
      user_update: function () {
        var self = this;
        instance.get("https://www.bazeny-obchod.cz/apieshop/uzivatel/", {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.data.informace != null) {
            self.user.informace = response.data.informace;
            self.logged = true;
          } else {
            self.user.informace = {};
            self.logged = false;
          }
        });
        localStorage.user = JSON.stringify(this.user);
      },
      /**
      
       * Favorites
       */
      add_to_favorites: function (product, origin = "card", link = product.seo_url) {
        var can = true;

        this.favorites.items.forEach(function (p) {
          if (product.id == p.favorite_item_array.id) {
            can = false;
          }
        });
        if (can) {
          this.favorites.items.push({
            favorite_item_id: this.uniqid("BZOB:", true),
            favorite_item_array: product,
            favorite_item_link: link,
            favorite_item_origin: origin
          });
          this.$buefy.snackbar.open({
            message: 'Produkt ' + product.nazev + ' byl přidán do oblíbených.',
            type: 'is-primary',
            position: 'is-bottom',
            indefinite: false
          });
          this.favorites_update();
        } else {
          this.$buefy.snackbar.open({
            message: 'Produkt ' + product.nazev + ' se již v oblíbených nachází.',
            type: 'is-primary',
            position: 'is-bottom',
            indefinite: false
          });
        }

      },
      remove_from_favorites: function (favorite_item_id) {
        var i = 0;
        while (i < this.favorites.items.length) {
          if (this.favorites.items[i].favorite_item_id === favorite_item_id) {
            this.favorites.items.splice(i, 1);
          } else {
            ++i;
          }
        }
        this.favorites_update();
      },
      favorites_update: function () {
        localStorage.favorites = JSON.stringify(this.favorites);
      },
      /**
       * Comparing
       */
      add_to_comparison: function (product_ii, origin = "card", link = product_ii.seo_url) {
        var self = this;
        let config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          }
        }
        axios.get("https://www.bazeny-obchod.cz/apieshop/produkt/" + product_ii.id, config).then(function (
          response) {
          var product = response.data;
          var can = true;

          self.comparison.items.forEach(function (p) {
            if (product.id == p.comparison_item_array.id) {
              can = false;
            }
          });
          if (can) {
            self.comparison.items.push({
              comparison_item_id: self.uniqid("BZOB:", true),
              comparison_item_array: product,
              comparison_item_link: link,
              comparison_item_origin: origin
            });
            self.$buefy.snackbar.open({
              message: 'Produkt ' + product.nazev + ' byl přidán do porovnání.',
              type: 'is-primary',
              position: 'is-bottom',
              actionText: 'Porovnání',
              indefinite: false,
              onAction: () => {
                self.$buefy.toast.open({
                  message: 'Action pressed',
                  queue: false
                })
              }
            });
            self.comparison_update();
          } else {
            self.$buefy.snackbar.open({
              message: 'Produkt ' + product.nazev + ' se již nachází v porovnání.',
              type: 'is-primary',
              position: 'is-bottom',
              actionText: 'Porovnání',
              indefinite: false,
              onAction: () => {
                self.$buefy.toast.open({
                  message: 'Action pressed',
                  queue: false
                })
              }
            });
          }
        });
      },
      remove_from_comparison: function (comparison_item_id) {
        var i = 0;
        while (i < this.comparison.items.length) {
          if (this.comparison.items[i].comparison_item_id === comparison_item_id) {
            this.comparison.items.splice(i, 1);
          } else {
            ++i;
          }
        }
        this.comparison_update();
      },
      comparison_update: function () {
        localStorage.comparison = JSON.stringify(this.comparison);
      },
      /**
       * Helper methods
       */
      uniqid: function (prefix = "", random = false) {
        const sec = Date.now() * 1000 + Math.random() * 1000;
        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
        return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
      },
      get_color(profile) {
        switch (profile) {
          case "main":
            return "#F3F3F3";

          case "fullpic":
            return "black";
          case "login":
            return "black";
          case "compare":
            return "black";
          case "postlogin":
            return "black";
          case "pageful":
            return "white";

          default:
            return "white";
        }
      },
      remove_from_array: function (cart_item_id) {
        var i = 0;
        while (i < this.cart.items.length) {
          if (this.cart.items[i].cart_item_id === cart_item_id) {
            this.cart.items.splice(i, 1);
          } else {
            ++i;
          }
        }
      },
      declickall: function () {
        if (document.getElementById('allcategories') != undefined) {

          var link = document.getElementById('allcategories');
          link.click(link);
        }

        if (document.getElementById('MenuCloseButton') != undefined) {

          var link_menuclose = document.getElementById('MenuCloseButton');
          link_menuclose.click(link_menuclose);
        }
      }
    },
    computed: {
      items_size() {
        var total = 0;
        var arr = this.cart.items;
        for (var i = 0; i < arr.length; i++) {
          total += arr[i].cart_item_amount
        }

        return total;
      },

      get_animation() {
        switch (this.profile) {
          case "main":
            return "zoom";

          case "fullpic":
            return "fadde";
          case "login":
            return "zoom";
          case "compare":
            return "zoom";
          case "postlogin":
            return "zoomr";
          case "pageful":
            return "fade";

          default:
            return "fade";
        }
      },
      get_type() {
        switch (this.profile) {
          case "main":
            return "bare";

          case "fullpic":
            return "path";
          case "login":
            return "bare";
          case "compare":
            return "bare";
          case "postlogin":
            return "bare";
          case "pageful":
            return "path";

          default:
            return "bare";
        }
      },
      get_theme() {
        switch (this.profile) {
          case "main":
            return "base";

          case "fullpic":
            return "white";
          case "login":
            return "white";
          case "compare":
            return "white";
          case "postlogin":
            return "white";
          case "pageful":
            return "base";

          default:
            return "base";
        }
      },
      get_back() {
        switch (this.profile) {
          case "main":
            return "BzOb_Color";

          case "fullpic":
            return "BzOb_Dark";

          case "login":
            return "BzOb_Dark";

          case "compare":
            return "BzOb_Dark";

          case "postlogin":
            return "BzOb_Dark";
          case "pageful":
            return "BzOb_White";

          default:
            return "BzOb_Color";
        }
      },
      get_back_s() {
        switch (this.profile) {
          case "main":
            return "";

          case "fullpic":
            return "";

          case "login":
            return "BzOb_Login";

          case "compare":
            return "BzOb_Compare";

          case "postlogin":
            return "BzOb_Login";
          case "pageful":
            return "";

          default:
            return "";
        }
      },
      get_sp_pad() {
        return "Sp_Pad " + this.profile;
      }
    }
  }
</script>

<style>
  :root {
    --UICompletor: 14px;
    --UIMinor: 16px;
    --UIMajor: 18px;
    --HeaderMicro: 34px;
    --HeaderMinor: 43px;
    --HeaderMajor: 103px;
    --PooltechnikBlue: #17B3CC;
    --PooltechnikBlueLight: #D7F2F7;
    --PooltechnikOrangeLight: #ffceb7;
    --PooltechnikOrange: #F37135;
    --PooltechnikOrangeDark: #D46004;
    --PooltechnikYellow: #F2D02A;
    --JicinBlue: #10549D;
    --JicinGold: #FFD035;
  }

  @media (prefers-reduced-motion) {
    * {
      animation: none !important;
      transition: none !important;
    }
  }

  .content {
    user-select: text;
  }

  .dropdown .background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    position: fixed;
    background-color: transparent;
    pointer-events: none;
    cursor: pointer;
    z-index: 50;
  }

  .dropdown {
    z-index: 500;
  }

  .BzOb_MenuLay {
    top: 0;
    left: 0;
    position: fixed;
    opacity: 0;
    pointer-events: none;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(15px);
    transition: all 0.3s ease;
    height: 100vh;
    z-index: 200;
  }

  .BzOb_MenuLay.light {
    background-color: rgba(0, 0, 0, 0);

  }

  .BzOb_MenuLay.active {
    opacity: 1;
    pointer-events: all;
    width: 100vw;
    transition: all 0.3s ease;
    height: 100vh;
  }

  body {
    user-select: none;
  }

  p {
    user-select: text;
  }

  #app {
    background-color: white;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: none;
    height: 100%;
    width: 100%;
    background-image: url("@/assets/background.svg");
    transition: background-color 1s ease;
    min-height: 100vh;
    overflow-x: hidden;
    font-synthesis: none;
    -moz-font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: ltr;
  }

  .BzObSrBxSf_Left::-webkit-scrollbar {
    display: none;
  }

  .UnderLayMenu {
    position: absolute;
    width: 100vw;
    height: 100%;
    overflow: scroll;
    padding: 50px;
  }


  .UnderLayMenu .Menu {
    width: 400px;
  }

  .SecondaryBackground {
    position: fixed;
    width: 100vw;
    opacity: 0;
    height: 100vh;
    background-image: url("@/assets/empty.png");
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    transition: background-image, opacity 1s ease;
    pointer-events: none;
  }


  .modal {
    z-index: 5000;
  }

  .is-menubutton {
    text-align: left !important;
    font-weight: bold;
    margin-bottom: 10px;
    justify-content: unset !important;
  }

  .BzObPc_ContentDown {
    background-color: #F6F6F6;
    /*min-height: 1540px;*/
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 768px) {
    .BzObPc_ContentDown {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .oddelovac svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 40px;
    transform: rotateY(180deg) rotate(180deg);
  }

  .menubutton-icon-wrapper {}

  .menubutton-icon {
    height: 25px;
    width: 25px;
    display: flex;
  }

  .BzOb_Color {
    background-color: #F3F3F3 !important;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;


  }

  .BzOb_Dark {
    background-color: #3f3f3f !important;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;


  }

  .BzOb_Login {
    opacity: 1;

    transition: background-image 1s ease;
    background-image: url("@/assets/login.png") !important;
  }

  .BzOb_Compare {
    opacity: 1;

    transition: background-image 1s ease;
    background-image: url("@/assets/compare_back.jpg") !important;
  }

  .main.Sp_Pad,
  .compare.Sp_Pad {
    padding-top: 130px;
    margin: auto;
    max-width: 1490px;

  }

  .Sp_PadSides {
    margin: auto;
    max-width: 1412px;
  }

  .pageful.Sp_Pad {
    padding-top: 190px;
    margin: auto;
    max-width: 1412px;

  }

  @media screen and (max-width: 768px) {
    .pageful.Sp_Pad {
      padding-top: 130px;
      margin: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .BzOb_White {
    background-color: white;
    transition: background-color 1s ease;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-moz-selection {
    color: white;
    background: var(--PooltechnikBlue);
  }

  ::selection {
    color: white;
    background: var(--PooltechnikBlue);
  }

  .RW {
    height: 100%;
  }

  .input,
  .button,
  .select select,
  .textarea {
    border-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-width: 2pt !important;
  }

  .fade-enter-active {
    -webkit-animation: fade-in-fwd 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .fade-leave-active {
    -webkit-animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom-enter-active {
    -webkit-animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom-leave-active {
    -webkit-animation: slide-out-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .zoomr-enter-active {
    -webkit-animation: slide-in-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoomr-leave-active {
    -webkit-animation: slide-out-top 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-top 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .zoomr_f-enter-active {
    -webkit-animation: slide-in-bottom-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoomr_f-leave-active {
    -webkit-animation: slide-out-top-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-top-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .zoom_f-enter-active {
    -webkit-animation: slide-in-top-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom_f-leave-active {
    -webkit-animation: slide-out-bottom-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  h1.BzObCc_title {
    font-size: var(--HeaderMajor);
    color: black;
    font-weight: bold;
  }

  h2.BzObCc_title {
    font-size: var(--HeaderMinor);
    color: black;
    font-weight: bold;
  }

  h3.BzObCc_title {
    font-size: var(--HeaderMicro);
    color: black;
    font-weight: bold;
  }

  h4.BzObCc_title {
    font-size: var(--UIMinor);
    color: black;
    font-weight: bold;
  }

  .notices {
    z-index: 80000 !important;
  }

  .pagination-link {
    background-color: #DEDEDE;
    border-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    font-weight: bold;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:3:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-top-f {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top-f {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-bottom-f {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom-f {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-top-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }
  }

  @keyframes slide-out-top-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
  }

  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:2:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
  }

  @-webkit-keyframes slide-out-bottom-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:1:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
  }

  @keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:0:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
  }

  @keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
  }

  @-webkit-keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  #app>div.Bz_Top>div>div.BzOb_NavigationBar>div>div.column.is-hidden-mobile.has-text-centered.is-narrow>div>div>div.control.SearchField.is-hidden-tablet-only.has-icons-right.is-clearfix>span {
    color: var(--PooltechnikBlue);
    height: 2.7em;
  }

  #app>div.Bz_Top>div>div.BzOb_NavigationBar>div>div.column.is-hidden-mobile.has-text-centered.is-narrow>div>div>div.control.SearchField.is-hidden-tablet-only.has-icons-right.is-clearfix>span>i {
    font-size: 23px !important
  }
</style>