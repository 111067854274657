<template>
  <div class="BzOb_Account" :class="{collapsed:collapsed}" @click="menu_opening">
    <img src="../../assets/menu_base.svg" v-if="theme == 'base'" />
    <img src="../../assets/menu_white.svg" v-if="theme == 'white'"/>
    <div class="BzObNavMob_Title no-select" v-if="mobile">Menu</div>
  </div>
  
</template>

<script>
export default {
  name: 'NavigationMenu',
  props: {
    theme: String,
    mobile: Boolean,
    collapsed: Boolean
  },
  methods: {
    menu_opening: function () {
      this.$emit('menu_opening');
    }
  }
}
</script>

<style scoped>
.BzOb_Account
{
  transition: all 0.3 ease-in-out;
}
.BzOb_Account.collapsed
{
  width: 0px;
  opacity: 0%
}
img,.BzOb_Account {
  height: 48px;
  cursor: pointer;
}
   @media screen and (max-width:768px) {
  img,.BzOb_Account {
  height: 36px;
  cursor: pointer;
}
}
</style>