<template>
    <div>
        <div class="Sp_NoPad"></div>
        <div :class="sp_pad" ref="wrapperHome">
            <MainCarousel :carousel="carousel" :animated="true" :animationDelay="3500" />
                    <br><br>
            <div class="columns">
                <div class="column is-narrow is-hidden-tablet-only is-hidden-mobile TOP_PARENT">
                   <div class="TOP"> <CategoryMenu :navigate="true" :has_top="true" :wrapper_width="width" @done="$parent.menu_lay = false" @menu_live="$parent.menu_lay = true" @menu_kaput="$parent.menu_lay = false"></CategoryMenu></div>
                </div>
                <div class="column">
                    
                    
                    <div class="columns is-multiline is-mobile is-variable is-1-mobile is-3-tablet" v-if="products.length >= 1">
                        <div class="column is-one-quarter-widescreen is-half-tablet is-full-mobile is-half-desktop PCard"
                            v-for="product in products" :key="product.id">
                            <section class="section1">
                                <ProductCard :product="product" v-if="product.dostupnost != '200'"
                                    @added_to_cart="$parent.add_to_cart" />
                            </section>
                        </div>
                    </div>
                    <div class="box">

                    <div class="content" v-html="leader"></div>
                    </div>
                    
                </div>
            </div>
            
        </div>
        <br><br><br><br><br>
        <NavigationFooter></NavigationFooter>
    </div>
</template>

<style scoped>
@media screen and (max-width: 1500px) {
        .Sp_Pad {
        padding-left: 25px;
        padding-right: 25px;
    }
}
.box {
    -webkit-box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 31px -20px rgba(0, 0, 0, 0.15);
        border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
}
</style><style>
.TOP_PARENT
{
    min-width: 300px;
    min-height: 640px;
    position:relative;
        padding-right: 25px;
}
.TOP
{
    width: calc(100% - 20px);
    position:absolute;
    z-index: 201;
}

</style>
<script>
    import MainCarousel from "../components/uicomponents/MainCarousel.vue";
    import NavigationFooter from "../components/footer_components/NavigationFooter.vue";
    import CategoryMenu from "../components/navigation/CategoryMenu.vue";
    import ProductCard from "../components/ecommerce/ProductCard.vue";
    import axios from "axios";
    export default {
        name: 'MainPage',
        title: "BazényObchod.cz",
        components: {
            MainCarousel,
            NavigationFooter,
            CategoryMenu,
            ProductCard
        },
        $refs: {
            wrapperHome: HTMLElement
        },
        metaInfo() {
        return { 
            title: this.meta.title + " | " + this.meta.site,
            meta: [
                { name: 'description', content:  this.meta.description},
                { property: 'og:description', content:  this.meta.description},
                { property: 'twitter:description', content:  this.meta.description},
                { property: 'og:title', content: this.meta.title + " | " + this.meta.site},
                { property: 'twitter:title', content: this.meta.title + " | " + this.meta.site},
                { property: 'og:site_name', content: this.meta.site},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'all,follow'} 
            ]
            }
        },
        data() {
            return {
                meta: {
                    description: "Bazén, Bazény, nafukovací bazény, bazénová chemie a písková filtrace od firmy s 20 letou tradicí. Bazény nadzemní, plastové, bazény v akci",
                    title: "Domov",
                    site: "BazényObchod.cz"
                },
                carousel_slides: [],
                products: [],
                leader: "",
                carousel: [{
                        type: "default",
                        title: "",
                        tagline: "",
                        image: "https://bazeny-obchod.cz/carousel/arcana.jpg",
                        tagposition: "top-left",
                        active: true
                    }
                    
                ],
                width: 300
            }
        },
        computed: {
            sp_pad() {
                return this.$parent.get_sp_pad;
            }
        },
        methods: {
          
            preload_slides: function () {
                this.carousel.forEach((element, index) => {
                    this.carousel_slides[index] = new Image();
                    this.carousel_slides[index].src = element.image;
                });
            },
            load_mainpage_products: function () {
                var self = this;
                axios.post("https://www.bazeny-obchod.cz/apieshop/produkty/", {
                        kategorie_info: '1',
                        strankovani: 3
                    })
                    .then(function (response) {

                        self.products = response.data.produkty;


                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            },

            load_texts: function () {
                var self = this;
                axios.get("https://www.bazeny-obchod.cz/apieshop/texty/")
                    .then(function (response) {

                        self.leader = response.data.text + response.data.textza;


                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            }
        },
        mounted() {
            this.preload_slides();
            this.load_mainpage_products();
            this.load_texts();
            this.width = this.$refs.wrapperHome.clientWidth;
            this.$Progress.finish();
                 window.addEventListener('resize',  () => {this.width = this.$refs.wrapperHome.clientWidth});
        },
        unmounted() {
            window.removeEventListener('resize', () => {this.width = this.$refs.wrapperHome.clientWidth});
        }
    }
</script>