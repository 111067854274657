<template>
  <div :class="theme === 'Bz_Top white' ? 'white ': 'Bz_Top'" v-if="!hide">
    <div :class="scrolling ? 'scrolling back': ''">
    <div class="BzOb_NavigationBar" :class="scrolling ? 'scrolling back' : ''">
      <div class="columns is-vcentered is-mobile is-centered BzObNb_Cols" :class="scrolling ? 'scrolling': ''">
       
        <div class="column is-narrow is-hidden-tablet">
          <NavigationInfoline :number="phone_num" :theme="theme" :collapsed="false" />
        </div>
        <div class="column is-hidden-tablet">
        </div>
        <div class="column is-narrow" :class="{isPaddingLess: icon_collapsed}">
          <NavigationLogo :type="type" :theme="theme" :collapsed="collapsed"/>
        </div>
        <!--<div class="column" :class="{ 'is-narrow': collapsed}"></div>-->
        <div class="column is-hidden-mobile is-hidden-tablet-only has-text-centered" >
          <NavigationSearch :theme="theme" :activated="collapsed" @activated="process" @deactivated="collapsed = false"/>
        </div>
        <!--<div class="column is-hidden-tablet-only is-hidden-mobile" :class="{ 'is-narrow': collapsed}"></div>-->
        <div class="column is-narrow  is-hidden-mobile">
          <NavigationSaleTags :theme="theme" v-if="!logged" :collapsed="collapsed" />
        </div>
        <div class="column is-narrow is-hidden-tablet-only is-hidden-desktop-only is-hidden-mobile">
          <NavigationInfoline :number="phone_num" :theme="theme" :collapsed="collapsed" />
        </div>
        <div class="column is-narrow is-hidden-mobile is-paddingless">
          <NavigationLove :theme="theme" :products="favorites" />
        </div>
        <div class="column is-narrow is-hidden-mobile is-paddingless">
          <NavigationCompare :theme="theme" :products="comparison" />
        </div>
        <div class="column is-narrow is-hidden-mobile">
          <NavigationCart :theme="theme" :cart="cart"/>
        </div>
        <div class="column is-hidden-tablet">
        </div>
        <div class="column is-narrow">
          <NavigationAccount :logged="logged" :user="user" />
        </div>
      </div>
    </div>
    <transition name="introduce">
      <div class="BzOb_Separator  is-hidden-mobile" v-if="(type !== 'bare' && !scrolling) && (path != null)"></div>
    </transition>
    <div class="BzObNb_MobileSearch is-hidden-tablet" :class="scrolling ? 'scrolling': ''">
      <NavigationSearch :theme="theme" :activated="collapsed" @activated="process" @deactivated="collapsed = false"/>
    </div>
    <div class="BzOb_SecondLevel is-hidden-mobile" v-if="(path != null && type !== 'bare')" :class="scrolling ? 'scrolling': ''">
      <div class="BzObNb_Cols columns is-gapless is-vcentered" :class="scrolling ? 'scrolling' : ''">
      <transition name="fadedown1"> <div v-if="type !== 'bare' && !scrolling" class="column  is-narrow">
          
            <NavigationPath :theme="theme" :path="path"/>
          
        </div></transition>
        <div class="column"></div>
        <transition name="fadedown2"><div v-if="type !== 'bare' && !scrolling" class="column is-narrow">
            <SocialIcons :theme="theme" />
          
        </div></transition>
      </div>
    </div>
  </div></div>
</template>

<script>
  import NavigationLogo from './NavigationLogo.vue'
  import NavigationAccount from './NavigationAccount.vue'
  import NavigationCart from './NavigationCart.vue'
  import NavigationCompare from './NavigationCompare.vue'
  import NavigationLove from './NavigationLove.vue'
  import NavigationInfoline from './NavigationInfoline.vue'
  import NavigationSearch from './NavigationSearch.vue'
  import NavigationPath from './NavigationPath.vue'
  import SocialIcons from './SocialIcons.vue'
import NavigationSaleTags from './NavigationSaleTags.vue'

  export default {
    name: 'NavigationBar',
    props: {
      theme: String,
      phone_num: String,
      type: String, 
      hide: Boolean,
      logged: Boolean, 
      comparison: Number,
      favorites: Number,
      icon_collapsed: Boolean,
      user: [Object, Array],
      cart: [Object, Array],
      path: [Object, Array]
    },
    components: {
    NavigationLogo,
    NavigationAccount,
    NavigationCart,
    NavigationCompare,
    NavigationLove,
    NavigationInfoline,
    NavigationSearch,
    NavigationPath,
    SocialIcons,
    NavigationSaleTags
},
    data() {
      return {
        collapsed: false,
        scrolling: false
      }
    },
    
    created() {
      this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      process: function(){
        this.collapsed = true;
      },
      handleScroll() {
        if(window.scrollY >= 100 && this.theme !== "white")
        {
          this.scrolling = true;
        }
        else
        {
          this.scrolling = false;
        }
      },
      menu_opening: function () {
        
        this.$emit('menu_opening');
      },
    }
  }
</script>

<style scoped>
.BzOb_SecondLevel {
    margin: auto;
    max-width: 1490px;
  }

.isPaddingLess
{
  padding-left: 0;
}
  .BzOb_SecondLevel .BzObNb_Cols {
    padding-top: 20px;
    margin-left: 40px;
    margin-right: 25px;
  }


  .BzOb_SecondLevel.scrolling {
    height: 0;
    pointer-events: none;
  }
  .BzOb_Separator {
    margin: auto;
    max-width: 1490px;
    height: 2px;

    background: #E4E4E4;
  }

  .introduce-enter-active {
    -webkit-animation: scale-in-hor-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-hor-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .introduce-leave-active {
    -webkit-animation: scale-in-hor-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
    animation: scale-in-hor-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
  }

  .white .BzOb_Separator {
    background: white;
  }
.back {
  transition: background 0.2s ease;
}
.scrolling.back {
  background: white;
  transition: background 0.2s ease;
}

  .BzObNb_Cols {

    padding-top: 30px;
    padding-bottom: 20px;

    margin-left: 20px;
    margin-right: 20px;
    transition: all 0.3s ease;
  }
 
  .BzObNb_Cols.scrolling {
    padding-top: 10px;
    padding-bottom: 0px;

    transition: all 0.3s ease;
  }
   @media screen and (max-width:768px) {
    .BzObNb_MobileSearch
    {
      margin-bottom: 5px;
      transition: all 0.3s ease;

    }
    .BzObNb_MobileSearch.scrolling
    {
      height: 0px;
      opacity: 0;
    }
   .BzObNb_Cols {

    margin-left: 50px!important;
    margin-right: 50px!important;
    padding-top: 5px;
    margin-bottom: -5px;
    padding-bottom: 0px;

  }
  .BzObNb_Cols.scrolling {
    padding-top: 5px;
    padding-bottom: 0px;

    transition: all 0.3s ease;
  }
}
  .BzOb_NavigationBar {
    margin: auto;
    max-width: 1490px;
    height: auto;
  }

  .BzOb_NavigationBar.scrolling {
    height: 104px;
  }


  .fadedown1-enter-active {
    -webkit-animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation-delay: 0.2s;
  }

  .fadedown1-leave-active {
    -webkit-animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
    animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
    animation-delay: 0.05s;
  }

  .fadedown2-enter-active {
    -webkit-animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation-delay: 0.2s;
  }

  .fadedown2-leave-active {
    -webkit-animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
    animation: fade-in-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
    animation-delay: 0.1s;
  }

  .Bz_Top.white {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
  }

  .Bz_Top {
    position: fixed;
    width: 100%;
    z-index: 9000;

  }

  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
      opacity: 0;
    }

    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
    }
  }

  @keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
      opacity: 0;
    }

    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
    }
  }
</style>