import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/views/MainPage.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Domov',
    meta: {
      title: 'Domov',
      profile: 'main',
      scroll_top: 0,
      track: false,
      icon_collapsed: true
    },
    component: MainPage,
  },
  {
    path: '/vitejte',
    name: 'Vítejte',
    meta: {
      title: 'Vítejte',
      profile: 'postlogin',
      track: false,
      scroll_top: 0,
      icon_collapsed: false
    },
    component: () => import('@/views/WelcomePage.vue')
  },
  {
    path: '/oblibene',
    name: 'Oblíbené produkty',
    meta: {
      title: 'Oblíbené',
      track: false,
      profile: 'main',
      scroll_top: 0,
      icon_collapsed: true
    },
    component: () => import('@/views/FavoritePage.vue')
  },
  {
    path: '/hledani/:searched',
    name: 'Vyhledávání',
    meta: {
      title: 'Vyhledávání',
      profile: 'main',
      track: false,
      scroll_top: 0,
      icon_collapsed: true
    },
    props: (route) => ({
      searched: Vue.observable(new Counter(route.params.searched))
    }),
    component: () => import('@/views/SearchPage.vue')
  },
  {
    path: '/porovnani',
    name: 'Porovnání produktů',
    meta: {
      title: 'Porovnání',
      track: false,
      profile: 'compare',
      scroll_top: 0,
      icon_collapsed: false
    },
    component: () => import('@/views/ComparePage.vue')
  },
  {
    path: '/heslo',
    name: 'Heslo',
    meta: {
      title: 'Heslo',
      profile: 'login',
      track: false,
      scroll_top: 0,
      icon_collapsed: true
    },
    component: () => import('@/views/PasswordPage.vue')
  },
  {
    path: '/zmena-hesla',
    name: 'Změna hesla',
    meta: {
      title: 'Změna hesla',
      profile: 'login',
      track: false,
      scroll_top: 0,
      icon_collapsed: false
    },
    component: () => import('@/views/PasswordChangePage.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  /*base: process.env.BASE_URL,*/
  base: "/",
  routes
  /*,
      scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve) => {
        
        setTimeout(() => {
          if (to.hash) {
            console.log("1" +to.hash);
            return resolve({ selector: to.hash });
          } else if (savedPosition) {
            console.log("2" );
            console.log(savedPosition);
            return resolve(savedPosition);
          } else {
            resolve(document.getElementById("scrollview").scrollIntoView({  }));
          }
        }, 1200);
      });
     
    }*/
})





const DEFAULT_TITLE = 'BazényObchod.cz';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
  });


});




class Counter {
  constructor(value) {
    this.searched = value
  }
}


export default router