<template>
  <div class="BzOb_Search" :class="theme">

    <div class="BzObSr_Box" :class="{active: active}">
      <b-input placeholder="Hledejte zde..." type="search" :custom-class="get_class"
        class="SearchField is-hidden-tablet-only" v-on:keyup.enter.native=" $router.push({ path: '/hledani/' + searched, query: { _r: Date.now() } });searched='';deactivate()" v-model="searched" v-debounce:50ms="search_do" icon-right="magnify" @focus="activate"
        @blur="deactivate">
      </b-input>
      <div class="BzObSrBx_SearchBox" :class="{ending:ending}" v-if="searched != ''">
        <div class="BzObSrBxSb_Info is-hidden-mobile">
          Nalezeno <b>{{results.counts.all}}</b> výsledků ({{results.time}}ms)
        </div>
        <b-tabs v-model="activeTab" class="tabssearch">
          <b-tab-item>
            <template #header>
              <span> <b>Vše</b> <span class="bl">({{results.counts.all}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.all == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">{{searched}} <b>jsme nenašli...</b></p>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound All" v-if="results.counts.all != 0">
              <div class="BzObSrBxSf_Left">
                <ul>
                  <li v-for="item in results.items.zbozi" :key="item.url"
                    class="BzObSrBxSfLf_Item">

                    <a :href="'https://bazeny-obchod.cz' + item.url">
                    <div class="columns is-mobile is-multiline is-variable is-1 is-vcentered" @click="deactivate_really">
                      <div class="column is-narrow">
                        <div class="BzObSrBxSfLf_Img">
                          <img :src="'https://www.bazeny-obchod.cz/'+item.obrazek" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="BzObSrBxSfLf_Text">
                          <p class="BzObSrBxSfLfTx_1">{{item.nazev}} </p>
                          <p class="BzObSrBxSfLfTx_2" v-for="param in item.params" :key="param.value">
                            <span class="has-text-grey-light">{{param.name}}: </span>
                            <span class="has-text-weight-bold">{{param.value}}</span>
                          </p>

                        </div>
                      </div>
                      <div class="column is-narrow is-hidden-mobile">
                        <img src="../../assets/eye3.svg" class="BzObSrBxSfLf_Eye" />
                      </div>
                      <div class="column is-narrow">
                        <p class="BzObSrBxSfLf_Price">
                          {{item.cena}}
                        </p>
                      </div>
                    </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="BzObSrBxSf_Right" v-if="results.items.kategorie">
               
                <ul>
                  <li v-for="item in results.items.kategorie" :key="item.nazev"
                    class="BzObSrBxSfRg_Item">
                    <a :href="'https://bazeny-obchod.cz' + (item.url.endsWith('/') ? item.url.slice(0, -1) : item.url)">
                    <div class="columns is-mobile is-variable is-1 is-vcentered" @click="deactivate_really">
                      <div class="column is-narrow">
                        <div class="BzObSrBxSfRg_Img Darken">
                                                    <img :src="'https://www.bazeny-obchod.cz/'+item.obrazek" />

                        </div>
                      </div>
                      <div class="column">
                        <div class="BzObSrBxSfRg_Text">
                          <p class="BzObSrBxSfRgTx_1">{{item.nazev}} <span>
                              <img src="../../assets/eye4.svg" class="BzObSrBxSfRg_Eye  is-hidden-mobile" />
                            </span></p>


                        </div>
                      </div>

                    </div>
                  </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> <b>Produkty</b> <span class="bl">({{results.counts.products}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.products == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Produkt „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound has-text-left BzObSrBx_ProductsOnly"
              v-if="results.counts.products != 0">
              <ul>
                <li v-for="item in results.items.zbozi" :key="item.url"
                  class="BzObSrBxSfLf_Item">
                  <a :href="'https://bazeny-obchod.cz' + item.url">
                  <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                    <div class="column is-narrow">
                      <div class="BzObSrBxSfLf_Img">
                        <img :src="'https://www.bazeny-obchod.cz/'+item.obrazek" />
                      </div>
                    </div>
                    <div class="column is-narrow">
                      <div class="BzObSrBxSfLf_Text">
                        <p class="BzObSrBxSfLfTx_1">{{item.nazev}} </p>
                        <p class="BzObSrBxSfLfTx_2" v-for="param in item.params" :key="param.value">
                          <span class="has-text-grey-light">{{param.name}}: </span>
                          <span class="has-text-weight-bold">{{param.value}}</span>
                        </p>

                      </div>
                    </div>
                    <div class="column">
                      <div class="BzObSrBxSfLf_Div"></div>
                    </div>
                    <div class="column is-narrow">
                      <img src="../../assets/eye3.svg" class="BzObSrBxSfLf_Eye" />
                    </div>
                    <div class="column is-narrow">
                      <p class="BzObSrBxSfLf_Price">
                        {{item.cena}}
                      </p>
                    </div>
                  </div>
                  </a>
                </li>
              </ul>
            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> <b>Kategorie</b> <span class="bl">({{results.counts.categories}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.categories == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Kategorii „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound" v-if="results.counts.categories != 0">
              <ul>
                 <li v-for="item in results.items.kategorie" :key="item.nazev"
                    class="BzObSrBxSfRg_Item">
                    <a :href="'https://bazeny-obchod.cz' + item.url">
                    <div class="columns is-mobile is-variable is-1 is-vcentered" @click="deactivate_really">
                      <div class="column is-narrow">
                        <div class="BzObSrBxSfLf_Img Darken">
                                                    <img :src="'https://www.bazeny-obchod.cz/'+item.obrazek" />

                        </div>
                      </div>
                         <div class="column is-narrow">
                        <div class="BzObSrBxSfLf_Text">
                          <p class="BzObSrBxSfLfTx_1">{{item.nazev}} <span>
                             
                            </span></p>


                        </div>
                      </div>
                      <div class="column">
                      <div class="BzObSrBxSfLf_Div"></div>
                    </div>
                    <div class="column is-narrow">
                      <img src="../../assets/eye3.svg" class="BzObSrBxSfLf_Eye" />
                    </div>
                   

                    </div>
                    </a>
                  </li>
              </ul>
            </div>
          </b-tab-item>
          
          <!--<b-tab-item>
            <template #header>
              <span> <b>Soubory</b> <span class="bl">({{results.counts.files}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.files == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Soubor „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound" v-if="results.counts.files != 0">

            </div>
          </b-tab-item>-->

        </b-tabs>
        <div class="is-hidden-tablet"><br><br><br></div>
      </div>
    </div>
    <img src="../../assets/search_base.svg" class="noselect is-hidden-desktop  is-hidden-mobile"
      v-if="theme == 'base'" />
    <img src="../../assets/search_white.svg" class="noselect is-hidden-desktop  is-hidden-mobile"
      v-if="theme == 'white'" />
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: 'NavigationSearch',
    props: {
      theme: String,
      activated: Boolean
    },
    data() {
      return {
        active: false,
        ending: false,
        searched: "",
        activeTab: 0,
        results: {
          time: 0.35,
          counts: {
            all: 0,
            products: 0,
            categories: 0,
            manufacturers: 0,
            files: 0
          },
          items: {
            kategorie: [],
            manufacturers: [],
            produkty: []
          }
        }
      }
    },
   /* watch: {
      searched() {
        if(this.searched != "")
        {
          this.search_do();
        }  
      }
    },*/
    computed: {
      get_class: function () {
        if (this.active) {
          return "borderless";
        }
        return "";
      }
    },
    methods: {
      search_do: function(){
        let startTime = Date.now();
        let endTime = null;
        var self = this;
        axios.get("https://www.bazeny-obchod.cz/apieshop/naseptavac/?q=" + this.searched).then(function(response) {
          endTime = Date.now();
          self.results.time = (endTime - startTime) / 1000;
          if(typeof response.data.error != "undefined")
          {
            self.results.items = [];
            self.results.counts.all = 0;
            self.results.counts.categories = 0;
            self.results.counts.products = 0;

          }
          else
          {
            self.results.items = response.data;
            if(typeof response.data.kategorie != "undefined")
              self.results.counts.categories = response.data.kategorie.length;
            if(typeof response.data.zbozi != "undefined")
              self.results.counts.products = response.data.zbozi.length;

            self.results.counts.all = self.results.counts.products + self.results.counts.categories;
          }
        });
      }, 

      activate: function () {
        this.ending = false;
        this.$emit('activated', true);
        this.active = true;

      },
      deactivate: function () {
        if (this.searched == "") {
          this.ending = true;
          setTimeout(() => {
            this.$emit('deactivated', true);
            this.active = false;
          }, 200);

        }
      },
      deactivate_really: function () {
        this.ending = true;
        setTimeout(() => {
          this.$emit('deactivated', true);
          this.active = false;

          this.searched = "";
        }, 200);


      }
    }
  }
</script>

<style>
  .borderless {
    transition: all 0.25s ease;
    border: none !important;
    box-shadow: none !important;
  }

  .tabssearch>.tabs ul {
    padding-left: 25px;
  }

  .tabssearch>.tabs li.is-active a {
    color: black !important;
  }

  .white .input {
    background: none;
  }

  .white .input::placeholder {
    color: white;
  }

  .white .active .input {
    background: white;
  }

  .white .active .input::placeholder {
    color: var(--PooltechnikBlue);
  }
</style>

<style scoped>
  .BzObSrBxSfRg_Img.Darken {
    mix-blend-mode: darken;
  }

  .BzObPcCd_FilterBorder {
    height: 10px;
    width: 100%;
    background-image: url("../../assets/border.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .BzObSrBxSfRg_Eye {
    height: 1rem;
    margin-bottom: -2px;
  }

  .BzObSrBx_ProductsOnly {
    padding: 9px
  }

  .BzObSrBxSfLf_Div {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    height: 1px;
    background-color: lightgray;
    transition: all 0.25s ease;
  }

  .BzObSrBxSfLf_Item {
    margin-bottom: 15px;
    transition: all 0.25s ease;
    cursor: pointer;
  }

  .BzObSrBxSfLf_Item:hover .BzObSrBxSfLf_Div {
    background-color: var(--PooltechnikOrange);
  }

  .BzObSrBxSfLf_Item:last-child {
    margin-bottom: 0px;
  }

  .BzObSrBxSfLf_Eye {
    height: 30px;

    margin-bottom: -6px;
    cursor: pointer;
  }

  .BzObSrBxSfRg_Img {
    height: 77px;
    width: 77px;
    position: relative;
    margin-right: 7px;
    overflow: hidden;
  }

  .BzObSrBxSfRg_Img img {
    height: auto;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

  }

  .BzObSrBxSfRg_Tx {
    color: var(--PooltechnikOrange);
  }

  .BzObSrBxSfLfTx_1 {
    color: var(--PooltechnikBlue);
    font-size: var(--UIMinor);
    transition: all 0.25s ease;
    font-weight: bold;
  }

  .BzObSrBxSfRgTx_1 {
    color: var(--PooltechnikBlue);
    font-size: var(--UIMinor);
    transition: all 0.25s ease;
    font-weight: bold;
  }

  .BzObSrBxSfLf_Item:hover .BzObSrBxSfLfTx_1,
  .BzObSrBxSfRg_Item:hover .BzObSrBxSfRgTx_1 {
    color: var(--PooltechnikOrange);
  }

  .BzObSrBxSfLf_Img {
    height: 77px;
    width: 77px;
    border-radius: 10px;
    transition: all 0.2s ease;
    margin-right: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
  }

  .BzObSrBxSfLf_Img img {
    height: 100%;
    width: 100%;
  }

  .BzObSrBxSfLf_Item:hover .BzObSrBxSfLf_Img {
    border-radius: 5px;
  }

  .tabssearch {
    height: 100%;
    margin-bottom: 0px!important;
  }

  .BzObSrBxSb_Info {
    position: absolute;
    width: calc(100% - 40px);
    margin: 20px;
    margin-top: 7px;
    text-align: right;
    pointer-events: none;
    color: grey;
  }

  .BzObSrBx_NothingFound {
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: flex-end;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .bl {
    color: var(--PooltechnikBlue)
  }

  .BzObSrBxNfTx_1 {
    font-size: var(--HeaderMinor);
    color: var(--PooltechnikBlue);
  }

  .BzObSrBxNfTx_2 {
    font-size: var(--UIMajor);
    margin-top: -15px;
  }

  .BzObSrBxSfLf_Price {
    color: var(--PooltechnikOrange);
    font-size: var(--UIMinor);
    font-weight: bold;
  }

  .BzOb_Search {
    margin: auto;
  }

  @media screen and (max-width: 768px) {
      .BzOb_Search.white
  {
    display: none;
  }
    .BzObSrBxSfLf_Img {
    height: 45px;
    width: 45px;
    
    border-radius: 5px;
    margin-right: 5px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.3);
    }
    .BzObSrBxSfRg_Img {
    height: 45px;
    width: 45px;
    position: relative;
    margin-right: 5px;
    overflow: hidden;
  }
  .BzObSrBxSfLfTx_1, .BzObSrBxSfRgTx_1 {
    font-size: var(--UICompletor);
    line-height: var(--UICompletor);
  }
  }


  img {
    height: 48px;
    pointer-events: none;
  }

  .input,
  b-input {
    font-size: var(--UIMinor) !important;
    height: 45px !important;
  }

  .BzObSrBxNf_Image {
    width: 250px;
    height: auto;

    margin-right: -50px;
  }

  .BzObSr_Box {
    height: 45px;
    width: 350px;
    margin: auto;
    position: relative;
    transition: width 0.25s ease-in-out;
  }


  .BzObSrBx_SomethingFound.All {
    display: flex;
    margin: -1rem;
    min-height: 400px;
    text-align: left;
  }

  .SearchField {
    position: absolute;
    z-index: 500000;
    width: 100%;
  }

  .BzObSr_Box.active {
    width: 100%;
    transition: width 0.25s ease-in-out;
    -webkit-box-shadow: 0px 0px 82px -37px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 82px -37px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 82px -37px rgba(0, 0, 0, 0.75);
  }


  .BzObSrBxSf_Right {
    background-color: #f3f3f3de;
    border-bottom-right-radius: 20px;
    width: 40%;
    min-height: 400px;
    padding: 25px
  }

  .BzObSrBxSf_Left {
    width: 60%;
    height: 100%;
    padding: 25px;
    overflow: scroll;
  }

  @media screen and (max-width: 768px) {
      .BzObSrBxSf_Left {
    overflow: hidden;
  }
    .BzObSr_Box {
      width: calc(100% - 50px);
      margin-left: 25px;
      margin-right: 25px;

      transition: all 0.25s ease-in-out;
    }

    .BzObSr_Box.active {
      width: calc(100% - 10px);
      margin-left: 5px;
      margin-right: 5px;

      transition: all 0.25s ease-in-out;
    }
    .BzObSrBxSf_Right {
    width: 50%;
    padding: 10px 15px;
  }

  .BzObSrBxSf_Left {
    width: 50%;
    height: 100%;
    padding: 10px 15px;
  }
  }
  .BzObSrBx_SearchBox {
    padding-top: 20px;
    width: 100%;
    -webkit-box-shadow: 0px 0px 100px -37px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 100px -37px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 100px -37px rgba(0, 0, 0, 0.5);
    min-height: 400px;
    max-height: 90vh;
    margin-top: 20px;
    z-index: 90000;
    overflow: scroll;
    position: absolute;
    background: white;
    -webkit-animation: scale-in-ver-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .BzObSrBx_SearchBox.ending {
    -webkit-animation: scale-out-ver-top 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-ver-top 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-11-13 19:17:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-out-ver-top
 * ----------------------------------------
 */
  @-webkit-keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  @keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-11-13 19:11:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
  @-webkit-keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  @keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
</style>