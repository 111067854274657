var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOpen)?_c('div',[_c('div',{ref:"mainmenubody",staticClass:"BzObMm_Menu",class:{closing: _vm.closing}},[(_vm.last_category_computed && _vm.last_category_computed.nazev !== '')?_c('h4',[_vm._v(_vm._s(_vm.last_category_computed.nazev))]):_vm._e(),(_vm.current_level > 1)?[_c('div',{staticClass:"BzObMmMn_Link category",on:{"click":() => {
              _vm.$router.push(_vm.last_category_computed.seo_nazev);
              _vm.close()
           }}},[_c('div',{staticClass:"icon"},[_c('b-icon',{attrs:{"icon":"view-dashboard"}})],1),_c('div',{staticClass:"name"},[_vm._v(" Přejít do kategorie ")])]),_c('div',{staticClass:"BzObMmMn_Link all",on:{"click":() => {
              _vm.current_level = 1;
              _vm.load_subcategories()
              _vm.current_level = 1;
           }}},[_c('div',{staticClass:"icon"},[_c('b-icon',{attrs:{"icon":"home"}})],1),_c('div',{staticClass:"name"},[_vm._v(" Všechny kategorie ")])]),_c('div',{staticClass:"BzObMmMn_Link back",on:{"click":function($event){return _vm.load_subcategories(_vm.last_category_computed, true)}}},[_c('div',{staticClass:"icon"},[_c('b-icon',{attrs:{"icon":"chevron-left"}})],1),_c('div',{staticClass:"name"},[_vm._v(" Zpět ")])])]:_vm._e(),_vm._l((_vm.links),function(link,l){return _c('div',{key:l,staticClass:"BzObMmMn_Link",class:{
      'no-icon': !link.ikonka,
      'active': _vm.path && Array.isArray(_vm.path) && _vm.path.map((el) => el.id).includes(link.id)
    },on:{"click":() => {
      if(link.podkategorie === 'true')
        {
          _vm.load_subcategories(link);
        }
      else
        {
          _vm.$router.push(link.seo_nazev);
          _vm.close()
        }
    }}},[(link.ikonka)?_c('div',{staticClass:"icon"},[_c('img',{staticClass:"menubutton-icon",attrs:{"src":'https://www.bazeny-obchod.cz'+link.ikonka}})]):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(link.nazev)+" ")]),(link.podkategorie === 'true')?_c('div',{staticClass:"append"},[_c('b-icon',{attrs:{"icon":"chevron-right"}})],1):_vm._e()])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }