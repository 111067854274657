<template>
  <router-link tag="div" class="BzOb_CompareIcn noselect" v-if="(products > 0 || windowWidth <= 768)" to="/porovnani">
      <img src="../../assets/compare_white.svg" v-if="(products == 0) && theme == 'white'" />
      <img src="../../assets/compare_white_n.svg" v-if="(products > 0 && products < 10) && theme == 'white'" />
      <img src="../../assets/compare_white_m.svg" v-if="(products >= 10) && theme == 'white'" />
      <img src="../../assets/compare_base.svg" v-if="(products == 0) && theme == 'base'" />
      <img src="../../assets/compare_base_n.svg" v-if="(products > 0 && products < 10) && theme == 'base'" />
      <img src="../../assets/compare_base_m.svg" v-if="(products >= 10) && theme == 'base'" />
      <div class="BzObLd_Counter1 noselect" v-if="products > 0 && products < 10">{{products_c}}</div>
      <div class="BzObLd_Counter2 noselect" v-if="products >= 10">{{products_c}}</div>
      <div class="BzObNavMob_Title" v-if="mobile">Porovnání</div>
    </router-link>
</template>

<script>
export default {
  name: 'NavigationCompare',
  props: {
    theme: String,
    mobile: Boolean,
    windowWidth: Number,
    products: Number
  },
  data() {
    return {
    }
  },
  methods: {
    get_comparing_products: function () {

    }
  },
  computed: {
    products_c() {
      if (this.products < 99)
        return this.products;
      else
        return "++";
    }
  }
}
</script>

<style scoped>
img {
  height: 48px;
  pointer-events: none;
}

.BzOb_CompareIcn {
  width: 48px;
  margin:12px; 
  cursor:pointer;
  position: relative;
}
@media screen and (max-width:768px) {
img {
  height: 36px;
  pointer-events: none;
}
.BzOb_CompareIcn {
  width: 100%;
  position:relative;
  height: 36px;
  margin: 0px;
  text-align: center;
}
}
.BzObLd_Counter1 {
  position: absolute;
  font-weight: bold;
  top: -0.7px;
  right: -0.5px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 18.7px;
  height: 18.7px;
}

.BzObLd_Counter2 {
  position: absolute;
  font-weight: bold;
  top: -0.7px;
  right: -0.5px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 26px;
  height: 18.7px;
}
</style>