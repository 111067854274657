<template>
  <div :class="theme === 'white' ? 'is-hidden ': 'Bz_Bottom'">
    <NavigationSaleTags :theme="theme" v-if="!logged" :collapsed="collapsed"/>
    <div :class="scrolling ? 'scrolling back': ''">
      <div class="BzOb_NavigationBar">
        <div class="columns is-vcentered is-mobile is-centered BzObNb_Cols" :class="scrolling ? 'scrolling': ''">
          <div class="column has-text-centered is-one-quarter">
            <NavigationMenu :logged="logged" :theme="theme" @menu_opening="menu_opening" :mobile="true"/>

          </div>
          <div class="column has-text-centered is-one-quarter">
            <NavigationLove :windowWidth="window_width" :products="favorites" :theme="theme" :mobile="true"/>

          </div>
          <div class="column has-text-centered is-one-quarter">
            <NavigationCompare :windowWidth="window_width" :products="comparison" :theme="theme" :mobile="true"/>

          </div>
          <div class="column has-text-centered is-one-quarter">
            <NavigationCart :theme="theme" :cart="cart" :mobile="true"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationCart from '../navbar_components/NavigationCart.vue'
import NavigationCompare from '../navbar_components/NavigationCompare.vue'
import NavigationLove from '../navbar_components/NavigationLove.vue'
import NavigationMenu from '../navbar_components/NavigationMenu.vue'
import NavigationSaleTags from '../navbar_components/NavigationSaleTags.vue'

export default {
  name: 'NavigationBar',
  props: {
    theme: String,
    type: String,
    comparison: Number,
    logged: Boolean,
    favorites: Number,
    window_width: Number,
    cart: [Object, Array],
    path: [Object, Array]
  },
  components: {
    NavigationCart,
    NavigationCompare,
    NavigationLove,
    NavigationMenu,
    NavigationSaleTags
  },
  data() {
    return {
      scrolling: false,
      collapsed: false,

    }
  },
  created() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    process: function () {
      this.collapsed = true;
    },
    handleScroll() {
      if (window.scrollY >= 100) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    },
    menu_opening: function () {

      this.$emit('menu_opening');
    },
  }
}
</script>
<style>.BzObNavMob_Title {
  font-size: 14px;
  color: var(--PooltechnikBlue);
  font-weight: 500;
  opacity: .8;
  margin-top: -7px;
}</style>
<style scoped>

.column {
  align-items: center;
}

.BzObNb_Cols {

  padding-top: 10px;
  padding-bottom: 10px;

  margin-left: 0px;
  margin-right: 0px;
  transition: all 0.3s ease;
}

.BzOb_NavigationBar {
  margin: auto;
  max-width: 1490px;
  height: 86px;
}

.Bz_Bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  position: fixed;
  width: calc(100%);
  z-index: 9000;
  bottom: 0;
  background: rgb(252, 252, 252);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-box-shadow: 0px 0px 53px -30px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 0px 53px -30px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 0px 53px -30px rgba(0, 0, 0, 0.49);
}

.Bz_Bottom.white {
  background-color: transparent
}

/* ----------------------------------------------
* Generated by Animista on 2023-2-2 20:25:14
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
</style>